import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  tabList: {
    paddingTop: theme.spacing(1),
  },
  tabLabel: {
    textTransform: 'none',
  },
  selectFieldsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    width: '100%',
  },
  selectField: {
    flex: 1,
  },
  configCard: {
    maxWidth: '35%',
  },
  configTabPanel: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(1),
  },
  configCardRoots: {
    maxWidth: '20%',
  },

  // ConversationTab
  conversationModalContainer: {
    width: '600px',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  conversationModalHeader: {
    display: 'flex',
    width: '100%',
    marginTop: '12px',
  },
  conversationModalHeaderTitle: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activePageUnderlined: {
    borderBottom: '2px solid',
    borderBottomColor: theme.palette.secondary.main,
  },
  divider: {
    margin: '5px 0px',
    width: '100%',
  },
  conversationTabContainer: {
    position: 'relative',
  },
  addCommentButton: {
    position: 'absolute',
    top: `-${theme.spacing(6)}`,
    right: 0,
    zIndex: 1,
  },
  // Comments
  commentsList: {
    maxHeight: '400px',
    overflowY: 'auto',
  },
  commentContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
  },
  commentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    gap: theme.spacing(1),
  },
  commentAuthor: {
    fontWeight: 500,
  },
  commentDate: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
  commentSeparator: {
    color: theme.palette.text.secondary,
  },
  commentMessage: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  newCommentSection: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export default useStyles;
