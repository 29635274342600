import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
} from '@mui/material';
import { useJsApiLoader } from '@react-google-maps/api';
import { useSnackbar } from 'notistack';
import Page from '../../../components/Layout/Page';
import useStyles from './styles';
import CustomHeader from '../../../components/General/CustomHeader';
import DevicesList from './component/Devices/DevicesList';
import SectorsList from './component/Sector/SectorsList';
import LayoutMap from './component/LayoutMap';
import CustomModal from '../../../components/General/CustomModal';
import UploadKMZ from './component/UploadKMZ';
import { PutSectorsKMZSchema } from '../../../requests/api/apiTypes';
import useFetchFarms from '../../../hooks/useFetchFarms';

const AVAILABLE_LIBRARIES: ('drawing' | 'places')[] = ['drawing', 'places'];

const LayoutListView = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [openUploadKMZModal, setOpenUploadKMZModal] = useState<{
    open: boolean, data: null | Array<PutSectorsKMZSchema[string]>
  }>({ open: false, data: null });
  const [cdtecSector, setCdtecSector] = useState<null | string>(null);
  const [KMZSector, setKMZSector] = useState<null | PutSectorsKMZSchema[string]>(null);
  const [mappedSectors, setMappedSectors] = useState({});

  const handleCloseUploadKMZModal = () => {
    setCdtecSector(null);
    setKMZSector(null);
    setMappedSectors({});
    setOpenUploadKMZModal({ open: false, data: null });
  };

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '';

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
    libraries: AVAILABLE_LIBRARIES,
  });

  useFetchFarms();

  useEffect(() => {
    if (cdtecSector && KMZSector) {
      enqueueSnackbar('Sector asociado correctamente', { variant: 'success' });

      // Safely update the state outside of render
      setMappedSectors((prevState) => ({
        ...prevState,
        [cdtecSector]: { id: KMZSector.id, coordinates: KMZSector.coordinates },
      }));

      setCdtecSector(null);
      setKMZSector(null);
    }
  }, [cdtecSector, KMZSector]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Page
      className={classes.root}
      title='Layout'
    >
      <Container maxWidth={false} className={classes.containerContainer}>
        <CustomHeader title='Layout' />
        <Box height='100%' display='flex' pt={3}>
          <Box marginRight='24px' className={classes.lateralListsContainer}>
            <SectorsList isLayout/>
          </Box>
          <LayoutMap
            setOpenUploadKMZModal={setOpenUploadKMZModal}
            setKMZSector={setKMZSector}
          />
          <Box marginLeft='24px' className={classes.lateralListsContainer}>
            <DevicesList />
          </Box>
        </Box>
      </Container>
      <CustomModal
        open={openUploadKMZModal.open}
        handleClose={handleCloseUploadKMZModal}
        cardClassName={classes.cardModalContainer}
      >
        <UploadKMZ
          handleCloseModal={handleCloseUploadKMZModal}
          polygons={openUploadKMZModal.data}
          KMZSector={KMZSector}
          mappedSectors={mappedSectors}
          cdtecSector={cdtecSector}
          setCdtecSector={setCdtecSector}
        />
      </CustomModal>
    </Page>
  );
};

export default LayoutListView;
