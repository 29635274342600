import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../styles';
import GoogleMaps from '../../../../components/Layout/Google/GoogleMaps';
import { ReduxState } from '../../../../types';
import { GetDevicesByFarmIdResponse } from '../../../../requests/api/apiTypes';
import { setAllFarmDevices, setFarmSector } from '../../../../actions/farmSectorActions';
import useFetchApi from '../../../../hooks/useFetchApi';

const SENSOR_TYPES = ['suelo', 'clima', 'planta', 'riego'] as const;
type SensorType = typeof SENSOR_TYPES[number];

const AccountHomeMap = () => {
  const classes = useStyles();
  const { selectedFarm } = useSelector((state: ReduxState) => state.accountFarm);
  const { selectedSector } = useSelector((state: ReduxState) => state.farmSector);
  const dispatch = useDispatch();
  const [selectedSensorTypes, setSelectedSensorTypes] = useState<Set<SensorType>>(
    new Set(SENSOR_TYPES),
  );

  const handleSectorClick = (sectorId: string) => {
    if (selectedSector === sectorId) {
      dispatch(setFarmSector(''));
    } else {
      dispatch(setFarmSector(sectorId));
    }
  };

  const { data } = useFetchApi<GetDevicesByFarmIdResponse>({
    modelEndpoint: 'farmsDetail',
    modelName: 'devices',
    id: selectedFarm?.id || '',
    dependencies: [selectedFarm?.id ?? ''],
  });

  useEffect(() => {
    if (data?.devices) {
      dispatch(setAllFarmDevices(data.devices));
    }
  }, [data?.devices, dispatch]);

  // Siempre partiran con todos los sensores seleccionados. Se puede cambiar con redux
  const toggleSensorType = (sensorType: SensorType) => {
    const newSelection = new Set(selectedSensorTypes);
    if (newSelection.has(sensorType)) {
      newSelection.delete(sensorType);
    } else {
      newSelection.add(sensorType);
    }
    setSelectedSensorTypes(newSelection);
  };

  // A lo mejor solo nos querremos mostrar los sectores que tengan un determinado tipo de sensor
  const polygonsAux = selectedFarm?.sectors?.map((sector) => ({
    id: sector.id,
    coordinates: sector?.coordinates ?? [],
    isSelected: selectedSector === sector.id,
    onPolygonClick: () => handleSectorClick(sector.id ?? ''),
  })) ?? [];

  const farmCoordinates = selectedFarm?.coordinates
    ? selectedFarm.coordinates.map(({ lat: farmLat, lng: farmLng }) => ({
      lat: typeof farmLat === 'string' ? parseFloat(farmLat) : farmLat,
      lng: typeof farmLng === 'string' ? parseFloat(farmLng) : farmLng,
    })) : null;

  const selectedSectorInfo = selectedFarm?.sectors?.find(
    (sector) => sector.id === selectedSector,
  );

  return (
    <Card className={classes.cardLayoutContainer}>
      <CardContent className={classes.cardLayoutContentContainer}>
        {!selectedFarm ? (
          <>
            <Typography
              variant='h3'
              color="primary"
              gutterBottom
              display="inline"
            >
              Mapa
            </Typography>
            <Divider className={classes.divider} />
            <Typography className={classes.noFarmSelectedText}>
              Elige un campo para ver el mapa
            </Typography>
          </>
        ) : (
          <>
            <Box className={classes.cardHeaderLayoutContentContainer}>
              <Box display='flex' alignItems='center'>
                <Box display='flex' alignItems='center' marginRight='20px'>
                  <Typography
                    variant="h3"
                    color="primary"
                  >
                    Mapa
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' paddingLeft='10px'>
                <Box display='flex' alignItems='center' flexWrap='wrap'>
                  <Chip
                    key='suelo'
                    color={selectedSensorTypes.has('suelo') ? 'primary' : undefined}
                    label="Suelo"
                    onClick={() => toggleSensorType('suelo')}
                    size='small'
                    className={classes.layoutMapChip}
                  />
                  <Chip
                    key='clima'
                    color={selectedSensorTypes.has('clima') ? 'primary' : undefined}
                    label="Clima"
                    onClick={() => toggleSensorType('clima')}
                    size='small'
                    className={classes.layoutMapChip}
                  />
                  <Chip
                    key='planta'
                    color={selectedSensorTypes.has('planta') ? 'primary' : undefined}
                    label="Planta"
                    onClick={() => toggleSensorType('planta')}
                    size='small'
                    className={classes.layoutMapChip}
                  />
                  <Chip
                    key='riego'
                    color={selectedSensorTypes.has('riego') ? 'primary' : undefined}
                    label="Riego"
                    onClick={() => toggleSensorType('riego')}
                    size='small'
                    className={classes.layoutMapChip}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={classes.googleMapsContainer}>
              <GoogleMaps
                lat={selectedFarm.center.lat}
                lng={selectedFarm.center.lng}
                drawable='none'
                farm={{
                  id: selectedFarm?.id ?? '',
                  coordinates: farmCoordinates,
                  zoom: selectedFarm?.zoom ?? 18,
                }}
                polygons={polygonsAux}
                sectorInfo={selectedSectorInfo ? {
                  data: {
                    Nombre: `${selectedSectorInfo?.name || '-'}`,
                  },
                } : null}
              />
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default AccountHomeMap;
