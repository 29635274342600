import dayjs from 'dayjs';
import { getFilteredReadings } from '../dataServices';
import {
  ENDPOINT,
  getGranularity,
  buildCategories,
  mapDates,
} from '../../../utils/highcharts';

const prepareData = async (filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sectorId?: string;
  wiseconnZoneId?: string;
}) => {
  const granularity = getGranularity(filters.startDate, filters.endDate);
  const categories = buildCategories(granularity, filters.startDate, filters.endDate);

  let hourInterval = 24;

  if (granularity === 'hourly') {
    hourInterval = 1;
  }

  // Execute API calls concurrently
  const [rainData] = await Promise.all([
    getFilteredReadings({
      ...filters,
      sensorType: 'Rain',
      hourInterval,
      endpoint: ENDPOINT[granularity],
    }),
  ]);

  // Create a mapping for `rainData` based on granularity
  const rainDataMap = mapDates(
    rainData, granularity, 'cumulativeTotal', granularity === 'hourly' ? 'hour' : 'date',
  );

  // Normalize `rainData` to ensure alignment with `categories`
  const normalizedRainData = (Array.isArray(categories) ? categories : categories.categories)
    .map((category: any) => {
      if (granularity !== 'weekly') {
        return rainDataMap.get(category) || 0;
      }

      // For weekly data, sum up the values for the week starting from this category
      const weekStart = dayjs(category);
      let weekTotal = 0;
      // Sum up values for the next 7 days
      for (let i = 0; i < 7; i += 1) {
        // Create a new date object for each iteration to avoid mutating weekStart
        const currentDate = weekStart.clone().add(i, 'day').format('MMM DD');
        const rainValue = rainDataMap.get(currentDate);
        weekTotal += typeof rainValue === 'number' ? rainValue : 0;
      }

      return weekTotal;
    });

  // Calculate cumulative rain by adding previous values
  const cumulativeRain = normalizedRainData.reduce<number[]>((acc, currentValue: any, index) => {
    const previousSum = index > 0 ? acc[index - 1] : 0;
    acc.push(previousSum + currentValue);
    return acc;
  }, []);

  return {
    rain: normalizedRainData, // Rainfall per hour
    cumulativeRain, // Progressive cumulative rainfall
    categories: Array.isArray(categories) ? categories : categories.categories, // Hours of the day
  };
};

export default prepareData;
