export const GRAPH_LIST_UI = [
  {
    label: 'Fecha Actualización', key: 'updatedAt',
  },
  {
    label: 'Actualizado por', key: 'updatedBy',
  },
  {
    label: 'Último comentario', key: 'lastComment',
  },
  {
    label: 'Campo', key: 'farm',
  },
  {
    label: 'Sector', key: 'sector',
  },
  {
    label: 'Gráfico', key: 'chart',
  },
  {
    label: 'Ver Conversación', key: 'viewConversation',
  },
];

export const formatDate = (isoDate: string, includeSeconds = true) => {
  const date = new Date(isoDate);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  if (includeSeconds) {
    return `${day}-${month}-${year} / ${hours}:${minutes}:${seconds}`;
  }
  return `${day}-${month}-${year} ${hours}:${minutes}`;
};

export const stringToColor = (string: string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = Math.abs((hash * 31) + string.charCodeAt(i));
  }

  const colors = [
    '#385576',
    '#1450A4',
    '#3498D1',
    '#1B6CB4',
    '#70BF07',
    '#C2E84D',
    '#429655',
    '#1A3B21',
    '#82480A',
    '#3A7B9E',
  ];

  return colors[hash % colors.length];
};
