import { getET0 } from '../dataServices';
import {
  ENDPOINT,
  buildCategories,
  getGranularity,
  getWeeklyAverageData,
  mapDates,
} from '../../../utils/highcharts';

const prepareData = async (filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sectorId?: string;
  wiseconnZoneId?: string;
}) => {
  const granularity = getGranularity(filters.startDate, filters.endDate);
  const categories = buildCategories(granularity, filters.startDate, filters.endDate);

  const et0Data = await getET0({
    farmId: filters.farmId,
    startDate: filters.startDate,
    endDate: filters.endDate,
    endpoint: granularity === 'hourly' ? ENDPOINT.hourly : ENDPOINT.et0,
    granularity,
  });

  let et0DataMap = mapDates(et0Data, granularity, 'et0');

  if (granularity === 'weekly') {
    et0DataMap = getWeeklyAverageData(et0DataMap as Map<string, number>);
  }

  const normalizedEt0Data = (Array.isArray(categories) ? categories : categories.categories)
    .map((category: any) => et0DataMap.get(category) || null);

  const cumulativeEt0 = normalizedEt0Data.map((_, index) => normalizedEt0Data
    .slice(0, index + 1).reduce((acc: any, curr: any) => acc + curr, 0));

  return {
    et0: normalizedEt0Data, // Et0 per hour
    cumulativeEt0, // Cumulative Et0
    categories: Array.isArray(categories) ? categories : categories.categories,
  };
};

export default prepareData;
