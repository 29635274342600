import {
  getET0,
  getAllDailyReadings,
  getLastIrrigationFromDate,
  calculateKc,
} from '../dataServices';
import {
  ENDPOINT,
  buildCategories,
  mapDates,
  getWeeklyAverageData,
  getGranularity,
} from '../../../utils/highcharts';

const prepareData = async (filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sectorId?: string;
  wiseconnZoneId?: string;
}) => {
  const granularity = getGranularity(filters.startDate, filters.endDate);

  // Categories for the chart (weekly)
  const categoriesForChart = buildCategories(
    granularity === 'hourly' ? 'daily' : granularity, filters.startDate, filters.endDate,
  );
  // Categories for the kc calculation (daily)
  const categoriesForKcCalculation = buildCategories('daily', filters.startDate, filters.endDate);
  // Fetch initial data in parallel
  const [irrigationData, lastIrrigationInfo] = await Promise.all([
    getAllDailyReadings({
      ...filters,
      name: 'Irrigation Precipitation',
    }),
    getLastIrrigationFromDate(filters),
  ]);
  const irrigationDataMap = mapDates(irrigationData, 'daily', 'maxValue');

  const normalizedIrrigationData = (Array.isArray(categoriesForKcCalculation)
    ? categoriesForKcCalculation : categoriesForKcCalculation.categories)
    .map((category: any) => irrigationDataMap.get(category) || 0);

  const { lastNonZeroIrrigationDate, lastNonZeroIrrigationValue } = lastIrrigationInfo;

  // Fetch ET0 data, important to have lastNonZeroIrrigationDate to use last irrigation
  const et0Data = await getET0({
    farmId: filters.farmId,
    startDate: lastNonZeroIrrigationDate,
    endDate: filters.endDate,
    endpoint: ENDPOINT.et0,
    // wiseconnZoneId: filters.wiseconnZoneId,
  });

  const { kcData } = await calculateKc({
    normalizedIrrigationData,
    et0Data,
    lastNonZeroIrrigationValue,
  });

  let et0DataMap = mapDates(et0Data, granularity, 'et0');

  if (granularity === 'weekly') {
    et0DataMap = getWeeklyAverageData(et0DataMap as Map<string, number>);
  }

  const normalizedEt0Data = (Array.isArray(categoriesForChart)
    ? categoriesForChart : categoriesForChart.categories)
    .map((category: any) => et0DataMap.get(category) || 0);

  let kcDataMap = mapDates(kcData, 'daily', 'kc');

  if (granularity === 'weekly') {
    kcDataMap = getWeeklyAverageData(kcDataMap as Map<string, number>);
  }

  const normalizedKcData = (Array.isArray(categoriesForChart)
    ? categoriesForChart : categoriesForChart.categories)
    .map((category: any) => kcDataMap.get(category) || 0);

  const result = {
    et0: normalizedEt0Data, // Et0 per hour
    kc: normalizedKcData, // Kc per day
    categories: Array.isArray(categoriesForChart)
      ? categoriesForChart : categoriesForChart.categories,
  };
  return result;
};

export default prepareData;
