import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Typography,
  CircularProgress,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import useStyles from '../styles';
import CardHeader from '../../../../components/General/CardHeader';
import { fetchWeatherForecast, Forecast } from '../utils';
import DailyForecast from './DailyForecast';
import { ReduxState } from '../../../../types';

const WeatherForecast = () => {
  const classes = useStyles();
  const { selectedFarm } = useSelector((state: ReduxState) => state.accountFarm);
  const [forecastData, setForecastData] = useState<Forecast | null>(null);
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const localLatitude = Number(selectedFarm?.center?.lat);
  const localLongitude = Number(selectedFarm?.center?.lng);

  const fetchForecastData = async () => {
    try {
      if (localLatitude || localLongitude) {
        setLoading(true);
        const updatedDate = new Date().toLocaleString();
        const weatherInformation = await fetchWeatherForecast(localLatitude, localLongitude);

        setLastUpdated(updatedDate);
        setForecastData(weatherInformation);
      }
    } catch (error) {
      enqueueSnackbar('Error al actualizar el pronóstico del clima', { variant: 'error' });
      setLastUpdated('Error - Intenta nuevamente');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchForecastData();
  }, [selectedFarm]);

  const renderContent = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      );
    }

    return (
      <>
        <CardHeader
          title='Pronóstico del Clima'
          ActionButton={
            <Box display="flex" alignItems="center">
              <Box display="flex" flexDirection="column" alignItems="flex-end" marginRight={1}>
                <Typography variant="body2" className={classes.lastUpdatedText}>
                  Última actualización:
                </Typography>
                <Typography variant="body2" className={classes.lastUpdatedDate}>
                  {lastUpdated || 'N/A'}
                </Typography>
              </Box>
              <IconButton onClick={fetchForecastData} color="primary">
                <RefreshIcon />
              </IconButton>
            </Box>
          }
          typographyVariant="h3"
          additionalLink="https://open-meteo.com/en/docs"
        />
        <Divider className={classes.divider} />
        <Box display="flex" justifyContent="space-between">
          {forecastData
            && Object.values(forecastData).map((dayData, index) => (
              <DailyForecast
                key={index}
                day={dayData.day}
                date={dayData.date}
                minTemperature={dayData.minTemperature}
                maxTemperature={dayData.maxTemperature}
                RainMm={dayData.RainMm}
                RainProbability={dayData.RainProbability}
                eto={dayData.eto}
                weather={dayData.weather}
              />
            ))}
        </Box>
      </>
    );
  };

  return (
    <Card>
      <CardContent>
      {!selectedFarm ? (
        <>
          <Typography
            variant='h3'
            color="primary"
            gutterBottom
            display="inline"
          >
            Pronóstico del Clima
          </Typography>
          <Divider className={classes.divider} />
          <Typography className={classes.noFarmSelectedText}>
            Elige un campo para ver el pronóstico del clima
          </Typography>
        </>
      ) : (
        renderContent()
      )}
      </CardContent>
    </Card>
  );
};

export default WeatherForecast;
