import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import BaseHighchart from '../BaseHighchart';
import prepareData from './builder';
import { ReduxState } from '../../../types';
import ChartFilter from '../ChartFilter';
import { navigateToGraph } from '../../../utils/helpers';

// To Do: Actualizar types
interface ChartDataType {
  series: { type: string; name: string; data: any[] }[];
  categories: string[];
}

function Et0Chart({
  sectorId,
  farmId,
  hideFilter = true,
  hideLegend = false,
  hideXValues = false,
  showIcons = true,
  height = 300,
  title = 'Gráfico Et0',
  yAxisTitle = 'Et0',
  isDashboardChart = false,
}: {
  sectorId: string
  farmId: string
  hideFilter?: boolean
  showIcons?: boolean
  hideLegend?: boolean
  hideXValues?: boolean
  height?: number
  title?: string
  yAxisTitle?: string
  isDashboardChart?: boolean
}) {
  const [chartData, setChartData] = useState<ChartDataType>({
    series: [],
    categories: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);

  const preparedFilters = useMemo(() => ({
    farmId,
    startDate,
    endDate,
  }), [farmId, startDate, endDate]);

  const createSeriesData = useCallback((preparedData: any) => [
    {
      type: 'bar',
      name: 'ETo',
      data: preparedData.et0,
      yAxis: 0,
      color: '#FEBF23',
    },
    {
      type: 'line',
      name: 'ETo acumulado',
      data: preparedData.cumulativeEt0,
      yAxis: 0,
      visible: false,
    },
  ], []);

  // To Do: Actualizar types
  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const preparedData = await prepareData(preparedFilters);

      const seriesData = createSeriesData(preparedData);

      setChartData({
        series: seriesData,
        categories: preparedData.categories,
      });
    } finally {
      setIsLoading(false);
    }
  }, [preparedFilters, createSeriesData]);

  // Llamada inicial al cargar el componente
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const customTooltip = useMemo(() => ({
    shared: true,
    useHTML: true,
    formatter(this: Highcharts.TooltipFormatterContextObject) {
      // const date = new Date(this.x as string).toLocaleDateString();
      const header = '<div style="font-size: 14px; font-weight: bold; margin-bottom: 8px;">Resumen</div>';
      const subHeader = `<div style="font-size: 12px; margin-bottom: 8px;">${this.x}</br></div>`;
      const points = this.points?.map(
        (point) => `<div style="margin: 4px 0"><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${Number(point.y).toFixed(2)}</b></div>`,
      ).join('');
      return `<div style="padding: 8px">${header}${subHeader}${points}</div>`;
    },
  }), []);

  const handleConfigClick = useCallback(() => {
    navigateToGraph('et0', history, {
      farmId,
      sectorId,
    });
  }, [history, farmId, sectorId]);

  return (
    <Box>
      {isLoading && (
        <Box display="flex" justifyContent="center" my={2}>
          <CircularProgress />
        </Box>
      )}
      <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
        <BaseHighchart
          isDashboardChart={isDashboardChart}
          title={title}
          categories={chartData.categories}
          yAxisTitle={yAxisTitle}
          series={chartData.series as Highcharts.SeriesOptionsType[]}
          tooltip={customTooltip}
          hideFilter={hideFilter}
          filter={<ChartFilter sectorId={sectorId} />}
          chartConfig={{
            chart: {
              height,
            },
          }}
          legend={{ enabled: !hideLegend }}
          hideXValues={hideXValues}
          onConfigClick={handleConfigClick}
          showIcons={showIcons}
        />
      </div>
    </Box>
  );
}

export default Et0Chart;
