import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import BaseHighchart from '../BaseHighchart';
import prepareData from './builder';
import { ReduxState } from '../../../types';
import ChartFilter from '../ChartFilter';
import { navigateToGraph } from '../../../utils/helpers';
import { getWeekInterval } from '../../../utils/highcharts';

// To Do: Actualizar types
interface ChartDataType {
  series: { type: string; name: string; data: any[] }[];
  categories: string[];
  kc: number[];
  granularity: string;
}

function IrrigationChart({
  sectorId,
  farmId,
  hideFilter = true,
  hideLegend = false,
  hideXValues = false,
  hideYValues = false,
  wiseconnZoneId,
  showIcons = true,
  height = 100,
  title = 'Gráfico de Riego',
  yAxisTitle = 'Kc / Et0 (mm) / Riego + Precipitaciones (mm)',
  isDashboardChart = false,
}: {
  sectorId: string
  farmId: string
  hideFilter?: boolean
  hideLegend?: boolean
  hideXValues?: boolean
  hideYValues?: boolean
  wiseconnZoneId: string | undefined
  showIcons?: boolean
  height?: number
  title?: string
  yAxisTitle?: string
  isDashboardChart?: boolean
}) {
  const [chartData, setChartData] = useState<ChartDataType>({
    series: [],
    categories: [],
    kc: [],
    granularity: '',
  });
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);

  // Memoize filters to prevent unnecessary re-renders
  const preparedFilters = useMemo(() => ({
    farmId,
    startDate,
    endDate,
    ...(wiseconnZoneId && { wiseconnZoneId }),
  }), [farmId, startDate, endDate, wiseconnZoneId]);

  // Memoize series data creation
  const createSeriesData = useCallback((preparedData: any) => [
    {
      type: 'column',
      name: 'Riego (mm)',
      data: preparedData.irrigation,
      yAxis: 0,
      stack: 'water',
      color: '#536e7b',
    },
    {
      type: 'column',
      name: 'Precipitaciones (mm)',
      data: preparedData.rain,
      yAxis: 0,
      stack: 'water',
      color: '#35a0d7',
    },
    {
      type: 'column',
      name: 'ETo (mm)',
      data: preparedData.et0,
      yAxis: 0,
      stack: 'et0',
      color: '#FEBF23',
    },
    {
      type: 'line',
      name: 'ETo entre riegos',
      data: preparedData.cumulativeEt0,
      yAxis: 0,
      color: 'rgb(228 107 56)',
      visible: false,
    },
  ], []);

  // Memoize fetch data function
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const preparedData = await prepareData(preparedFilters);
      const seriesData = createSeriesData(preparedData);

      setChartData({
        series: seriesData,
        categories: preparedData.categories,
        kc: preparedData.kc as number[],
        granularity: preparedData.granularity,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [preparedFilters, createSeriesData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const customTooltip = useMemo(() => ({
    shared: true,
    useHTML: true,
    style: {
      zIndex: 9999,
      position: 'absolute',
    },
    formatter(this: Highcharts.TooltipFormatterContextObject) {
      const pointIndex = this.points?.[0].point.index;
      const kcValue = chartData.kc[pointIndex as number];
      const category = chartData.granularity === 'weekly'
        ? getWeekInterval(chartData.categories[pointIndex as number])
        : chartData.categories[pointIndex as number];
      const subHeader = `<div style="font-size: 12px; margin-bottom: 8px;">${category}</br></div>`;
      const header = '<div style="font-size: 14px; font-weight: bold; margin-bottom: 8px;">Resumen</div>';
      const points = this.points?.map(
        (point) => `<div style="margin: 4px 0"><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${Number(point.y).toFixed(2)}</b></div>`,
      ).join('');
      const kcLine = `<div style="margin: 4px 0"><span style="color:#6FBF23">\u25CF</span> Kc: <b>${Number(kcValue).toFixed(2)}</b></div>`;
      return `<div style="padding: 8px; position: relative">${header}${subHeader}${points}${kcLine}</div>`;
    },
  }), [chartData.kc, chartData.categories, chartData.granularity]);

  const handleConfigClick = useCallback(() => {
    navigateToGraph('riego', history, {
      farmId,
      sectorId,
      wiseconnZoneId: wiseconnZoneId || '',
    });
  }, [history, farmId, sectorId, wiseconnZoneId]);

  const chartConfig = {
    chart: {
      height,
    },
    xAxis: {
      categories: hideXValues ? [] : chartData.categories,
      plotBands: chartData.categories.map((_, index) => ({
        color: index % 2 === 0 ? 'rgba(0, 0, 0, 0.02)' : 'rgba(173, 216, 230, 0.3)',
        from: index - 0.5,
        to: index + 0.5,
      })),
      labels: {
        enabled: true,
        formatter(this: Highcharts.AxisLabelsFormatterContextObject): string {
          const index = this.pos;
          const kcValue = chartData.kc[index];
          const numKcValue = Number(kcValue);

          let backgroundColor;
          if (numKcValue < 0.63) {
            backgroundColor = '#BF3F23';
          } else if (numKcValue >= 0.63 && numKcValue <= 0.65) {
            backgroundColor = '#FEBF23';
          } else {
            backgroundColor = '#6FBF23';
          }

          return `<span style="
            background-color: ${backgroundColor}; 
            color: white;
            padding: 2px 6px;
            border-radius: 4px;
            font-weight: bold;
            position: relative;
            z-index: 1;
          ">${numKcValue.toFixed(2)}</span>`;
        },
        useHTML: true,
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
        },
      },
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
        pointWidth: 18,
      },
    },
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={height}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <BaseHighchart
        isDashboardChart={isDashboardChart}
        title={title}
        categories={chartData.categories}
        yAxisTitle={yAxisTitle}
        series={chartData.series as Highcharts.SeriesOptionsType[]}
        tooltip={customTooltip}
        hideFilter={hideFilter}
        filter={<ChartFilter sectorId={sectorId} />}
        chartConfig={chartConfig}
        hideYValues={hideYValues}
        legend={{ enabled: !hideLegend }}
        onConfigClick={handleConfigClick}
        showIcons={showIcons}
        height={height}
      />
    </Box>
  );
}

export default IrrigationChart;
