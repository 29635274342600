import { useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { Box, Tab } from '@mui/material';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import CustomHeader from '../../components/General/CustomHeader';
import Page from '../../components/Layout/Page';
import useStyles from './styles';
import IrrigationChart from '../../components/Highcharts/IrrigationChart/IrrigationChart';

const Irrigation = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const [tabValue, setTabValue] = useState('1');

  const queryParams = new URLSearchParams(search);
  const params = {
    farmId: queryParams.get('farmId') || '',
    sectorId: queryParams.get('sectorId') || '',
    wiseconnZoneId: queryParams.get('wiseconnZoneId') || '',
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <Page
      className={classes.root}
      title='Riego'
    >
      <Container maxWidth={false}>
        <CustomHeader title='TO DO TITULO' />
        <Box mt={3}>
          <IrrigationChart
            hideFilter={false}
            sectorId={params.sectorId}
            farmId={params.farmId}
            wiseconnZoneId={params.wiseconnZoneId}
            showIcons={false}
          />
        </Box>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 0 }}>
            <TabList onChange={handleTabChange} aria-label="sector tabs">
              <Tab label="Conversaciones" value="1" className={classes.tabLabel}/>
            </TabList>
          </Box>
          <TabPanel value="1">
            In progress
          </TabPanel>
        </TabContext>
      </Container>
    </Page>
  );
};
export default Irrigation;
