/* eslint-disable @typescript-eslint/no-use-before-define, no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListSubheader,
  TextField,
  Autocomplete,
} from '@mui/material';

import {
  AccountCircle as AccountCircleIcon,
  Announcement as AnnouncementIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Dashboard as DashboardIcon,
  Home as HomeIcon,
  LocalOffer as LocalOfferIcon,
  Message as MessageIcon,
  Nature as NatureIcon,
  Person as PersonIcon,
  Sensors as SensorsIcon,
  Warning as WarningIcon,
  Water as WaterIcon,
  Work as WorkIcon,
} from '@mui/icons-material/';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NavItem from './NavItem';
import { NavigationItemProps, ReduxState } from '../../../types';

import useStyles from './styles';
import useFetchApi from '../../../hooks/useFetchApi';
import { AccountSchema, GetUserAccountsByUserIdResponse } from '../../../requests/api/apiTypes';
import { setUserAccount } from '../../../actions/userAccountAction';
import { setAccountFarm } from '../../../actions/accountFarmAction';

const navConfig = [
  {
    items: [
      {
        title: 'Usuarios',
        icon: PersonIcon,
        href: '/usuarios',
      },
      {
        title: 'Cuentas',
        icon: AccountCircleIcon,
        href: '/cuentas',
      },
      {
        title: 'Empresas',
        icon: WorkIcon,
        href: '/empresas',
      },
      {
        title: 'Equipos',
        icon: SensorsIcon,
        href: '/equipos',
      },
      {
        title: 'Especies y variedades',
        icon: NatureIcon,
        href: '/especies-variedades',
      },
    ],
  },
];

const specificNavConfig = [
  {
    items: [
      {
        title: 'Inicio',
        icon: HomeIcon,
        href: '/inicio', // definir despues
      },
      {
        title: 'Estado sensores',
        icon: AnnouncementIcon,
        href: '/estado-sensores', // definir despues
      },
      {
        title: 'Layout',
        icon: DashboardIcon,
        href: '/layout', // `/cliente/${clientId}/layout`, // definir despues REVISAR
      },
      {
        title: 'Riego',
        icon: WaterIcon,
        href: '/riego', // definir despues
      },
      {
        title: 'Conversaciones',
        icon: MessageIcon,
        href: '/conversaciones', // definir despues
      },
      {
        title: 'Alertas',
        icon: WarningIcon,
        href: '/alertas', // definir despues
      },
      {
        title: 'Órdenes de trabajo',
        icon: LocalOfferIcon,
        href: '/ordenes-de-trabajo', // `/cliente/${clientId}/ordenes-de-trabajo`, // definir despues REVISAR
      },
      {
        title: 'Equipos',
        icon: SensorsIcon,
        href: '/cliente-equipos', // definir despues
      },
      {
        title: 'Usuarios',
        icon: PersonIcon,
        href: '/cliente-usuarios', // definir despues
      },
    ],
  },
];

function renderNavItems(items: NavigationItemProps[], pathname: string): JSX.Element {
  return (
    <List disablePadding>
      {items?.reduce(
        (acc: JSX.Element[], item) => reduceChildRoutes(acc, pathname, item),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes(
  acc: JSX.Element[],
  pathname: string,
  item: NavigationItemProps,
  depth = 0,
) {
  const key = `${item.title || ''}depth`;

  if (item.items && item.items.length > 0) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems(item.items, pathname)}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />,
    );
  }

  return acc;
}

function NavBar({
  openMobile, onMobileClose, openDrawer, onDrawerAction,
}: {
  openMobile: boolean, onMobileClose: () => void,
  openDrawer: boolean, onDrawerAction: () => void
}) {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReduxState) => state.account);
  const { selectedAccount } = useSelector((state: ReduxState) => state.userAccount);
  const navigate = useHistory();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const { data } = useFetchApi<GetUserAccountsByUserIdResponse>({
    modelName: 'userAccounts',
    modelEndpoint: 'userAccountsDetail',
    id: user?.id,
    dependencies: [selectedAccount?.id ?? ''],
  });
  const localAccounts = data?.userAccounts;

  // Select the first one on first render if there is more than 2
  if ((localAccounts && localAccounts.length >= 1)
    && !selectedAccount?.id) {
    const account = localAccounts[0];
    dispatch(setUserAccount(account));
    if (account.farms && account.farms.length > 0) {
      dispatch(setAccountFarm(account.farms[0]));
    }
  }

  const onAccountChange = (_event: React.SyntheticEvent, newValue: AccountSchema | null) => {
    if (newValue) {
      dispatch(setUserAccount(newValue));

      // Reload the current page
      navigate.go(0);
    }
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {navConfig.map((config, index) => (
            <List
              key={config.items[index].title}
              subheader={(
                <ListSubheader disableGutters disableSticky />
              )}
            >
              {renderNavItems(config.items, location.pathname)}
            </List>
          ))}
          <Divider />
          <Box key='account-container' width='100%' mt={2} mb={2}>
            {localAccounts && localAccounts.length > 0 && selectedAccount ? (
              <Autocomplete
                key='account-item'
                options={localAccounts}
                getOptionLabel={(option) => option.name}
                value={selectedAccount}
                onChange={onAccountChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Cuenta'
                    variant='standard'
                    fullWidth
                  />
                )}
                disableClearable
                disabled={localAccounts.length === 1}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            ) : (
              <TextField
                key='account-item'
                label='Cuenta'
                name='selectedAccount'
                variant='standard'
                fullWidth
                value=''
                disabled
              />
            )}
          </Box>
          {selectedAccount?.id && specificNavConfig.map((config, index) => (
            <List
              key={config.items[index].title}
              subheader={(
                <ListSubheader disableGutters disableSticky />
                //   {config.subheader}
                // </ListSubheader>
              )}
            >
              {renderNavItems(config.items, location.pathname)}
            </List>
          ))}
          {!selectedAccount?.id && <ListItem disabled>
            Selecciona un cliente
          </ListItem>}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return <>
    <Hidden lgUp>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {content}
      </Drawer>
    </Hidden>
    <Hidden lgDown>
      <Drawer
        anchor="left"
        classes={{ paper: classes.desktopDrawer }}
        open={openDrawer}
        variant="persistent"
      >
        {content}
      </Drawer>
      <Box
        onClick={onDrawerAction}
        className={openDrawer ? `${classes.openCloseDrawerBox} ${classes.openDrawerIcon}` : `${classes.openCloseDrawerBox} ${classes.closedDrawerIcon}`}>
        {openDrawer ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </Box>
    </Hidden>
  </>;
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  onDrawerAction: PropTypes.func,
  openDrawer: PropTypes.bool,
};

export default NavBar;
