import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import BaseHighchart from '../BaseHighchart';
import prepareData from './builder';
import { ReduxState } from '../../../types';
import ChartFilter from '../ChartFilter';
import { navigateToGraph } from '../../../utils/helpers';

// To Do: Actualizar types
interface ChartDataType {
  series: { type: string; name: string; data: any[] }[];
  categories: string[];
}

function RainfallChart({
  sectorId,
  farmId,
  hideFilter = true,
  showIcons = true,
  height = 300,
}: {
  sectorId: string
  farmId: string
  hideFilter?: boolean
  showIcons?: boolean
  height?: number
}) {
  const [chartData, setChartData] = useState<ChartDataType>({
    series: [],
    categories: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  const title = 'Gráfico de Precipitaciones';
  const yAxisTitle = 'Precipitaciones (mm)';
  const history = useHistory();

  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);

  const preparedFilters = useMemo(() => ({
    farmId,
    startDate,
    endDate,
  }), [farmId, startDate, endDate]);

  const createSeriesData = useCallback((preparedData: any) => [
    {
      type: 'bar',
      name: 'Precipitaciones (mm)',
      data: preparedData.rain,
      yAxis: 0,
    },
    {
      type: 'line',
      name: 'Precipitaciones Acumuladas (mm)',
      data: preparedData.cumulativeRain,
      yAxis: 0,
    },
  ], []);

  // To Do: Actualizar types
  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const preparedData = await prepareData(preparedFilters);
      const seriesData = createSeriesData(preparedData);

      setChartData({
        series: seriesData,
        categories: preparedData.categories,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [preparedFilters, createSeriesData]);

  // Llamada inicial al cargar el componente
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const customTooltip = {
    shared: true,
    useHTML: true,
    formatter(this: Highcharts.TooltipFormatterContextObject) {
      // const date = new Date(this.x as string).toLocaleDateString();
      const header = '<div style="font-size: 14px; font-weight: bold; margin-bottom: 8px;">Resumen</div>';
      const subHeader = `<div style="font-size: 12px; margin-bottom: 8px;">${this.x}</br></div>`;
      const points = this.points?.map(
        (point) => `<div style="margin: 4px 0"><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${Number(point.y).toFixed(2)}</b></div>`,
      ).join('');
      return `<div style="padding: 8px">${header}${subHeader}${points}</div>`;
    },
  };

  const handleConfigClick = () => {
    navigateToGraph('precipitaciones', history, {
      farmId,
      sectorId,
    });
  };
  return (
    <Box>
      {isLoading && (
        <Box display="flex" justifyContent="center" my={2}>
          <CircularProgress />
        </Box>
      )}
      <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
        <BaseHighchart
          title={title}
          categories={chartData.categories}
          yAxisTitle={yAxisTitle}
          series={chartData.series as Highcharts.SeriesOptionsType[]}
          tooltip={customTooltip}
          hideFilter={hideFilter}
          filter={<ChartFilter sectorId={sectorId} />}
          onConfigClick={handleConfigClick}
          showIcons={showIcons}
          chartConfig={{
            chart: {
              height,
            },
          }}
        />
      </div>
    </Box>
  );
}

export default RainfallChart;
