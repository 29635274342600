const SOIL_TYPES = [
  'Arcilloso',
  'Arenoso',
  'Limoso',
  'Franco',
  'Franco Arenoso',
  'Franco Limoso',
  'Franco Arcilloso',
  'Franco Arcillo Arenoso',
  'Franco Arcillo Limoso',
  'Arcillo Arenoso',
  'Arcillo Limoso',
  'Areno Francoso',
];

// eslint-disable-next-line import/prefer-default-export
export { SOIL_TYPES };
