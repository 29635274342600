// Start of Selection
const phenologicalStatesNameOptions = [
  'Germinación',
  'Emergencia',
  'Crecimiento vegetativo (Primavera)',
  'Crecimiento vegetativo (Otoño-Invierno)',
  'Desarrollo foliar',
  'Desarrollo de brotes',
  'Floración',
  'Cuajado de frutos',
  'Crecimiento de frutos',
  'Maduración y terminación de frutos',
  'Caída de hojas',
  'Receso invernal',
  'Cosecha',
  'Poscosecha',
  'Poscosecha 2',
  'Pinta',
  'Endurecimiento de carozo',
  'Latencia',
  'Brotación',
];

export default phenologicalStatesNameOptions;
