import React, { useState } from 'react';
import {
  Box, Divider, Grid, IconButton, MenuItem, TextField, Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import useStyles from '../styles';

import { PhenologicalState, PhenologicalStateWithId } from '../../../../../requests/api/apiTypes';
import CustomButton from '../../../../../components/General/CustomButton';
import MonthDayPicker from '../../../../../components/General/MonthDayPicker';
import phenologicalStatesNameOptions from '../../../../Varieties/types';

const UpsertPhenologicalStateModalContent = ({
  handleCloseModal,
  handleUpsertFunction,
  id,
  initialData,
} : {
  handleCloseModal: VoidFunction,
  handleUpsertFunction: (PhenologicalState: PhenologicalStateWithId) => void,
  id?: string,
  initialData?: PhenologicalState
}) => {
  const classes = useStyles();

  const [newPhenologicalState, setNewPhenologicalState] = useState<PhenologicalStateWithId>({
    name: initialData?.name || '',
    startDate: initialData?.startDate || '',
    endDate: initialData?.endDate || '',
    uuid: id || '',
  });

  const handleDataChange = (value: string, name: string) => {
    setNewPhenologicalState({
      ...newPhenologicalState,
      [name]: value,
    });
  };

  const handleUpsertPhenologicalState = () => {
    handleUpsertFunction({
      name: newPhenologicalState.name,
      startDate: newPhenologicalState.startDate,
      endDate: newPhenologicalState.endDate,
      uuid: id || uuidv4(),
    });
  };

  return (
    <Box className={classes.upsertCropCycleModalContainer}>
      <Box className={classes.upsertCropCycleModalHeader}>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.upsertCropCycleModalHeaderTitle}
        >
          <span className={classes.activePageUnderlined}>
            Agregar estado fenológico
          </span>
        </Typography>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider className={classes.dividerStyle} />
      <Grid container spacing={2}>
        <Grid item sm={12} md={12}>
          <TextField
            label="Nombre"
            name="name"
            variant="standard"
            value={newPhenologicalState.name ?? ''}
            onChange={(e) => handleDataChange(e.target.value, 'name')}
            select
            fullWidth
          >
            {phenologicalStatesNameOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={6} md={6}>
          <MonthDayPicker
            label="Inicio"
            value={newPhenologicalState.startDate}
            onChange={(value) => handleDataChange(value, 'startDate')}
            error={false}
            helperText=""
            includeYear={true}
          />
        </Grid>
        <Grid item sm={6} md={6}>
          <MonthDayPicker
            label="Fin"
            value={newPhenologicalState.endDate}
            onChange={(value) => handleDataChange(value, 'endDate')}
            error={false}
            helperText=""
            includeYear={true}
          />
        </Grid>
      </Grid>
      <Box className={classes.upsertCropCycleModalButtonsContainer}>
        <Box width='100%' mr={2}><CustomButton color='blue' buttonText='Cancelar' onClick={handleCloseModal} /></Box>
        <Box width='100%' ml={2}><CustomButton color='green' buttonText={id ? 'Editar' : 'Agregar'} onClick={handleUpsertPhenologicalState} /></Box>
      </Box>
    </Box>
  );
};

export default UpsertPhenologicalStateModalContent;
