export const calculateEsTmax = (maxTemperature: number): number => {
  const numerator = 17.27 * maxTemperature;
  const denominator = 237.3 + maxTemperature;
  return 0.611 * (2.718 ** (numerator / denominator));
};

export const calculateEsTmin = (minTemperature: number): number => {
  const numerator = 17.27 * minTemperature;
  const denominator = 237.3 + minTemperature;
  return 0.611 * (2.718 ** (numerator / denominator));
};

export const calculateEa = (
  esTmax: number,
  esTmin: number,
  minHumidity: number,
  maxHumidity: number,
): number => {
  const firstPart = esTmax * (minHumidity / 100); // Convertimos porcentaje a decimal
  const secondPart = esTmin * (maxHumidity / 100); // Convertimos porcentaje a decimal
  return (firstPart + secondPart) / 200;
};

export const calculateRnl = (
  maxTemperature: number,
  minTemperature: number,
  ea: number,
  solarRadiation: number,
): number => {
  const averageTemperature = (maxTemperature + minTemperature) / 2;
  const stefanBoltzmannFactor = (0.086 * solarRadiation) / 33.377;

  const rnl = (averageTemperature * 0.34 - (0.14 * Math.sqrt(ea)))
    * (1.35 * stefanBoltzmannFactor - 0.35);
  return rnl;
};

export const calculateRns = (solarRadiation: number): number => 0.77 * (0.086 * solarRadiation);

export const calculateRn = (rns: number, rnl: number): number => rns - rnl;

export const calculateEs = (esTmax: number, esTmin: number): number => (esTmax + esTmin) / 2;

export const calculateS = (es: number, averageTemperature: number): number => {
  const denominator = (averageTemperature + 237.3) ** 2;
  return (4098 * es) / denominator;
};

export const calculateET0 = (
  s: number,
  rn: number,
  averageTemperature: number,
  windVelocity: number,
  es: number,
  ea: number,
): number => {
  // Convertir la velocidad de viento de km/h a m/s
  const windVelocityMps = windVelocity / 3.6;

  const numerator = 0.408 * s * rn
    + 0.066 * ((900 / (averageTemperature + 273)) * windVelocityMps * (es - ea));

  const denominator = s + 0.066 * (1 + 0.34 * windVelocity);

  return numerator / denominator;
};
