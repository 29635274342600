import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import {
  Box, Card, TextField, Tab, CardContent, MenuItem, CircularProgress,
} from '@mui/material';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { useSnackbar } from 'notistack';
import CustomHeader from '../../components/General/CustomHeader';
import Page from '../../components/Layout/Page';
import useStyles from './styles';
import StackedSoilMoistureChart from '../../components/Highcharts/StackedSoilMoistureChart/StackedSoilMoistureChart';
import { GetSectorStackedSoilMoistureConfigResponse } from '../../requests/api/apiTypes';
import useFetchApi from '../../hooks/useFetchApi';
import apiClient from '../../requests/api/apiClient';
import { FetchError } from '../types';

const StackedSoilMoisture = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const [tabValue, setTabValue] = useState('1');
  const [isLoading, setIsLoading] = useState(true);

  const queryParams = new URLSearchParams(search);
  const params = {
    farmId: queryParams.get('farmId') || '',
    sectorId: queryParams.get('sectorId') || '',
    wiseconnZoneId: queryParams.get('wiseconnZoneId') || '',
  };

  const { enqueueSnackbar } = useSnackbar();
  const [sortBy, setSortBy] = useState('');

  const { data, loading } = useFetchApi<GetSectorStackedSoilMoistureConfigResponse>({
    modelEndpoint: 'stackedSoilMoistureConfigDetail',
    modelName: 'stackedSoilMoistureConfig',
    id: params.sectorId,
    dependencies: [params.sectorId],
  });

  useEffect(() => {
    if (!loading) {
      setSortBy(data?.stackedSoilMoistureConfig?.sortBy || 'Humedad');
      setIsLoading(false);
    }
  }, [data, loading]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleSortByChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSortBy(event.target.value);
    try {
      const { data: response } = await apiClient
        .stackedSoilMoistureConfig
        .stackedSoilMoistureConfigCreate(
          params.sectorId,
          { sortBy: event.target.value },
        );
      enqueueSnackbar(response.message, { variant: 'success' });
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Page
      className={classes.root}
      title='Humedad suelo apilada'
    >
      <Container maxWidth={false}>
        <CustomHeader title='TO DO TITULO' />
        <Box mt={3}>
          <StackedSoilMoistureChart
            hideFilter={false}
            sectorId={params.sectorId}
            farmId={params.farmId}
            wiseconnZoneId={params.wiseconnZoneId}
            showIcons={false}
          />
        </Box>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 0 }}>
            <TabList onChange={handleTabChange} aria-label="sector tabs">
              <Tab label="Configuración" value="1" className={classes.tabLabel}/>
              <Tab label="Conversaciones" value="3" className={classes.tabLabel}/>
            </TabList>
          </Box>
          <TabPanel value="1" className={classes.configTabPanel}>
            <Card className={classes.configCard}>
              <CardContent>
                <Box className={classes.selectFieldsContainer}>
                  <TextField
                    label="Ordenar por"
                    variant='standard'
                    value={sortBy}
                    onChange={handleSortByChange}
                    select
                    className={classes.selectField}
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value="Humedad">Humedad</MenuItem>
                    <MenuItem value="Profundidad">Profundidad</MenuItem>
                  </TextField>
                </Box>
              </CardContent>
            </Card>
          </TabPanel>
          <TabPanel value="3">
            In progress
          </TabPanel>
        </TabContext>
      </Container>
    </Page>
  );
};
export default StackedSoilMoisture;
