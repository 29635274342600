import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import BaseHighchart from '../BaseHighchart';
import prepareData from './builder';
import { ReduxState } from '../../../types';
import ChartFilter from '../ChartFilter';
import { navigateToGraph } from '../../../utils/helpers';

// To Do: Actualizar types
interface ChartDataType {
  series: { type: string; name: string; data: any[] }[];
  categories: string[];
}

function KcChart({
  sectorId,
  farmId,
  hideFilter = true,
  hideTitle = false,
  hideLegend = false,
  wiseconnZoneId,
  showIcons = true,
  height = 300,
  title = 'Gráfico Kc',
  isDashboardChart = false,
}: {
  sectorId: string
  farmId: string
  hideFilter?: boolean
  hideTitle?: boolean
  hideLegend?: boolean
  wiseconnZoneId: string | undefined
  showIcons?: boolean
  height?: number
  title?: string
  isDashboardChart?: boolean
}) {
  const [chartData, setChartData] = useState<ChartDataType>({
    series: [],
    categories: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);

  const preparedFilters = useMemo(() => ({
    farmId,
    startDate,
    endDate,
    wiseconnZoneId,
  }), [farmId, startDate, endDate, wiseconnZoneId]);

  const createSeriesData = useCallback((preparedData: any) => [
    {
      type: 'column',
      name: 'Kc',
      data: preparedData.kc,
      yAxis: 0,
      stack: 'kc',
      zones: [
        {
          value: 0.5,
          color: '#BF3F23', // Rojo
        },
        {
          value: 0.6,
          color: '#FEBF23', // Amarillo
        },
        {
          color: '#6FBF23', // Verde para mayor a 2
        },
      ],
    },
  ], []);

  // To Do: Actualizar types
  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const preparedData = await prepareData(preparedFilters);
      const seriesData = createSeriesData(preparedData);

      setChartData({
        series: seriesData,
        categories: preparedData.categories,
      });
    } finally {
      setIsLoading(false);
    }
  }, [preparedFilters, createSeriesData]);

  // Llamada inicial al cargar el componente
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const customTooltip = useMemo(() => ({
    shared: true,
    useHTML: true,
    formatter(this: Highcharts.TooltipFormatterContextObject) {
      // const date = new Date(this.x as string).toLocaleDateString();
      const header = '<div style="font-size: 14px; font-weight: bold; margin-bottom: 8px;">Resumen</div>';
      const subHeader = `<div style="font-size: 12px; margin-bottom: 8px;">${this.x}</br></div>`;
      const points = this.points?.map(
        (point) => `<div style="margin: 4px 0"><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${Number(point.y).toFixed(2)}</b></div>`,
      ).join('');
      return `<div style="padding: 8px">${header}${subHeader}${points}</div>`;
    },
  }), []);

  const handleConfigClick = useCallback(() => {
    navigateToGraph('kc', history, {
      farmId,
      sectorId,
      wiseconnZoneId: wiseconnZoneId || '',
    });
  }, [history, farmId, sectorId, wiseconnZoneId]);

  return (
    <Box style={{ width: '100%' }}>
      {isLoading && (
        <Box display="flex" justifyContent="center" my={2}>
          <CircularProgress />
        </Box>
      )}
      <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
        <BaseHighchart
          isDashboardChart={isDashboardChart}
          title={hideTitle ? '' : title}
          categories={chartData.categories}
          series={chartData.series as Highcharts.SeriesOptionsType[]}
          tooltip={customTooltip}
          hideFilter={hideFilter}
          filter={<ChartFilter sectorId={sectorId} />}
          chartConfig={{
            chart: {
              height,
            },
          }}
          hideXValues={false}
          legend={{ enabled: !hideLegend }}
          onConfigClick={handleConfigClick}
          showIcons={showIcons}
        />
      </div>
    </Box>
  );
}

export default KcChart;
