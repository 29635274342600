import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import {
  Box, Card, CardContent, Tab, TextField, CircularProgress,
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useSnackbar } from 'notistack';
import CustomHeader from '../../components/General/CustomHeader';
import Page from '../../components/Layout/Page';
import useStyles from './styles';
import RootsChart from '../../components/Highcharts/RootsChart/RootsChart';
import useFetchApi from '../../hooks/useFetchApi';
import { GetSectorRootsConfigResponse, ConversationSchema, TimePointSchema } from '../../requests/api/apiTypes';
import apiClient from '../../requests/api/apiClient';
import { FetchError } from '../types';
import { ROOTS_NAME } from '../../constants/graphs';
import ConversationsTab from '../../components/Highcharts/ConversationTab';

const Roots = ({ breadcrumb }: { breadcrumb: string }) => {
  const classes = useStyles();
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = useState('1');
  const [isLoading, setIsLoading] = useState(true);
  const [modalState, setModalState] = useState<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>({
    open: false,
    conversationId: '',
    timePointData: {
      hour: '',
      day: '',
      month: '',
      year: '',
    },
  });
  const [conversations, setConversations] = useState<ConversationSchema[]>([]);
  const [conversationsLoading, setConversationsLoading] = useState(false);
  const [irrigationThreshold, setIrrigationThreshold] = useState<number>(0);
  const [rootsThreshold, setRootsThreshold] = useState<number>(0);

  const queryParams = new URLSearchParams(search);
  const params = {
    farmId: queryParams.get('farmId') || '',
    sectorId: queryParams.get('sectorId') || '',
    wiseconnZoneId: queryParams.get('wiseconnZoneId') || '',
  };

  useEffect(() => {
    const loadConversations = async () => {
      setConversationsLoading(true);
      try {
        const { data } = await apiClient.conversation.conversationDetail(
          params.sectorId,
          ROOTS_NAME,
        );
        setConversations(data.conversations);
      } catch (err) {
        const { error } = err as FetchError;
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        setConversationsLoading(false);
      }
    };

    loadConversations();
  }, [params.farmId]);

  const { data, loading } = useFetchApi<GetSectorRootsConfigResponse>({
    modelEndpoint: 'rootsConfigDetail',
    modelName: 'rootsConfig',
    id: params.sectorId,
    dependencies: [params.sectorId],
  });

  useEffect(() => {
    if (!loading) {
      setIrrigationThreshold(data?.rootsConfig?.irrigationThreshold || 1);
      setRootsThreshold(data?.rootsConfig?.rootsThreshold || 0.2);
      setIsLoading(false);
    }
  }, [data, loading]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleThresholdsChange = async (value: number, type: 'root' | 'irrigation') => {
    try {
      if (type === 'root') {
        setRootsThreshold(value);
        const { data: response } = await apiClient.rootsConfig.rootsThresholdCreate(
          params.sectorId, { threshold: value },
        );
        enqueueSnackbar(response.message, { variant: 'success' });
      } else {
        setIrrigationThreshold(value);
        const { data: response } = await apiClient.rootsConfig.irrigationThresholdCreate(
          params.sectorId, { threshold: value },
        );
        enqueueSnackbar(response.message, { variant: 'success' });
      }
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Page
      className={classes.root}
      title='Profundidad de Riego'
    >
      <Container maxWidth={false}>
        <CustomHeader title={breadcrumb} />
        <Box mt={3}>
          <RootsChart
            breadcrumb={breadcrumb}
            hideFilter={false}
            sectorId={params.sectorId}
            farmId={params.farmId}
            wiseconnZoneId={params.wiseconnZoneId}
            thresholds={{
              irrigationThreshold,
              rootsThreshold,
            }}
            externalModalState={modalState}
            externalSetModalState={setModalState}
            externalConversations={conversations}
            externalSetConversations={setConversations}
          />
        </Box>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 0 }}>
            <TabList onChange={handleTabChange} aria-label="sector tabs">
              <Tab label="Configuración" value="1" className={classes.tabLabel}/>
              <Tab label="Conversaciones" value="2" className={classes.tabLabel}/>
            </TabList>
          </Box>
          <TabPanel value="1" className={classes.configTabPanel}>
            <Card className={classes.configCardRoots}>
              <CardContent>
                <Box className={classes.selectFieldsContainer}>
                  <TextField
                    className={classes.selectField}
                    label="Umbral de actividad raiz"
                    type="number"
                    value={rootsThreshold}
                    onChange={(e) => handleThresholdsChange(parseFloat(e.target.value), 'root')}
                    fullWidth
                    variant="standard"
                    inputProps={{
                      step: '0.01',
                      style: { textAlign: 'right' },
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
            <Card className={classes.configCardRoots} sx={{ marginTop: 2 }}>
              <CardContent>
                <Box className={classes.selectFieldsContainer}>
                  <TextField
                    className={classes.selectField}
                    label="Umbral de irrigación"
                    type="number"
                    value={irrigationThreshold}
                    onChange={(e) => handleThresholdsChange(parseFloat(e.target.value), 'irrigation')}
                    fullWidth
                    variant="standard"
                    inputProps={{
                      step: '0.01',
                      style: { textAlign: 'right' },
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </TabPanel>
          <TabPanel value="2">
            <ConversationsTab
              setModalState={setModalState}
              conversations={conversations}
              conversationsLoading={conversationsLoading}
            />
          </TabPanel>
        </TabContext>
      </Container>
    </Page>
  );
};
export default Roots;
