import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import {
  Box, Card, CardContent, Tab, TextField, CircularProgress,
} from '@mui/material';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { useSnackbar } from 'notistack';
import CustomHeader from '../../components/General/CustomHeader';
import Page from '../../components/Layout/Page';
import useStyles from './styles';
import Et0Chart from '../../components/Highcharts/Et0Chart/Et0Chart';
import useFetchApi from '../../hooks/useFetchApi';
import { GetSectorEt0ConfigResponse } from '../../requests/api/apiTypes';
import { FetchError } from '../types';
import apiClient from '../../requests/api/apiClient';

const Et0 = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const [tabValue, setTabValue] = useState('1');
  const [isLoading, setIsLoading] = useState(true);

  const queryParams = new URLSearchParams(search);
  const params = {
    farmId: queryParams.get('farmId') || '',
    sectorId: queryParams.get('sectorId') || '',
  };

  const { enqueueSnackbar } = useSnackbar();
  const [accSinceDate, setAccSinceDate] = useState('');

  const { data, loading } = useFetchApi<GetSectorEt0ConfigResponse>({
    modelEndpoint: 'et0ConfigDetail',
    modelName: 'et0Config',
    id: params.sectorId,
    dependencies: [params.sectorId],
  });

  useEffect(() => {
    if (!loading) {
      setAccSinceDate(data?.et0Config?.accumulateFrom || new Date().toISOString().split('T')[0]);
      setIsLoading(false);
    }
  }, [data, loading]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleDateChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccSinceDate(event.target.value);
    try {
      const { data: response } = await apiClient.et0Config.et0ConfigCreate(
        params.sectorId,
        { accumulateFrom: event.target.value },
      );
      enqueueSnackbar(response.message, { variant: 'success' });
    } catch (err) {
      const { error } = err as FetchError;
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Page
      className={classes.root}
      title='Evapotranspiración'
    >
      <Container maxWidth={false}>
        <CustomHeader title='TO DO TITULO' />
        <Box mt={3}>
          <Et0Chart
            hideFilter={false}
            sectorId={params.sectorId}
            farmId={params.farmId}
            showIcons={false}
          />
        </Box>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 0 }}>
            <TabList onChange={handleTabChange} aria-label="sector tabs">
              <Tab label="Configuración" value="1" className={classes.tabLabel}/>
              <Tab label="Conversaciones" value="2" className={classes.tabLabel}/>
            </TabList>
          </Box>
          <TabPanel value="1" className={classes.configTabPanel}>
            <Card className={classes.configCard}>
              <CardContent>
                <Box className={classes.selectFieldsContainer}>
                  <TextField
                    className={classes.selectField}
                    label="Acumular desde"
                    type="date"
                    value={accSinceDate}
                    onChange={handleDateChange}
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </CardContent>
            </Card>
          </TabPanel>
          <TabPanel value="2">
            In progress
          </TabPanel>
        </TabContext>
      </Container>
    </Page>
  );
};
export default Et0;
