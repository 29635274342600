import React from 'react';
import IrrigationChart from '../../../../../../components/Highcharts/IrrigationChart/IrrigationChart';
import StackedSoilMoistureChart from '../../../../../../components/Highcharts/StackedSoilMoistureChart/StackedSoilMoistureChart';
import SoilMoistureChart from '../../../../../../components/Highcharts/SoilMoistureChart/SoilMoistureChart';
import RootsChart from '../../../../../../components/Highcharts/RootsChart/RootsChart';
import useStyles from '../../../../../../components/Highcharts/styles';

function SoilDashboard({
  sectorId, farmId, wiseconnZoneId, breadcrumb,
}: { sectorId: string, farmId: string, wiseconnZoneId: string | undefined, breadcrumb: string }) {
  const classes = useStyles();

  return (
    <div className={classes.dashboardContainer}>
      <IrrigationChart
        isDashboardChart={true}
        hideFilter={true}
        hideLegend={false}
        hideYValues={false}
        hideXValues={true}
        sectorId={sectorId}
        farmId={farmId}
        wiseconnZoneId={wiseconnZoneId}
        height={160}
        yAxisTitle=""
        title="Kc y mm"
      />
      <RootsChart
        isDashboardChart={true}
        hideFilter={true}
        hideLegend={false}
        hideXValues={true}
        hideYValues={false}
        sectorId={sectorId}
        farmId={farmId}
        wiseconnZoneId={wiseconnZoneId}
        height={140}
        yAxisTitle=""
        title="Profundidad riego y raíces"
        breadcrumb={breadcrumb}
      />
      <StackedSoilMoistureChart
        isDashboardChart={true}
        hideFilter={true}
        hideLegend={false}
        hideXValues={true}
        hideYValues={false}
        sectorId={sectorId}
        farmId={farmId}
        wiseconnZoneId={wiseconnZoneId}
        height={350}
        title="Humedad de suelo"
        yAxisTitle=" "
      />
      <SoilMoistureChart
        isDashboardChart={true}
        hideFilter={true}
        hideYValues={false}
        hideXValues={false}
        hideLegend={true}
        sectorId={sectorId}
        farmId={farmId}
        wiseconnZoneId={wiseconnZoneId}
        height={350}
        title="Sumatoria humedad de suelo"
        yAxisTitle=" "
        breadcrumb={breadcrumb}
      />
    </div>
  );
}

export default SoilDashboard;
