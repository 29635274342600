import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';

import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../../../components/General/CustomButton';
import useStyles from '../../styles';
import CustomModal from '../../../../../components/General/CustomModal';
import AddPolygons from '../AddPolygons';
import { FetchError } from '../../../../types';
import apiClient from '../../../../../requests/api/apiClient';
import { ReduxState } from '../../../../../types';
import { setAccountFarm, setAccountFarmsOptions } from '../../../../../actions/accountFarmAction';
import {
  FarmSchema, GetFarmsWiseconnIdsResponse, PostFarmSchema, UpdateFarmSchema,
} from '../../../../../requests/api/apiTypes';
import useFetchApi from '../../../../../hooks/useFetchApi';

const FARM_NAME_CONVERSIONS = {
  name: 'Nombre',
  theoreticalSurface: 'Superficie Total',
  location: 'Ubicación',
  center: 'Coordinadas',
  wiseconnId: 'Wiseconn ID',
};

type InfoFarmKeys = 'name' | 'theoreticalSurface' | 'location' | 'center';

const UpsertFarmModal = ({
  modalState,
  setModalState,
}: {
  modalState: {
    open: boolean;
    toEdit: boolean;
  };
  setModalState: React.Dispatch<React.SetStateAction<{
    open: boolean;
    toEdit: boolean;
  }>>;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedAccount } = useSelector((state: ReduxState) => state.userAccount);
  const { selectedFarm, farmsOptions } = useSelector((state: ReduxState) => state.accountFarm);
  const [openSecondModalFarmFlow, setOpenSecondModalFarmFlow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [farmInformation, setFarmInformation] = useState<FarmSchema>({
    name: '',
    theoreticalSurface: '',
    location: '',
    center: {
      lat: '',
      lng: '',
    },
    wiseconnId: '',
    zoom: 18,
  });
  const [error, setError] = useState<FarmSchema>({
    name: '',
    theoreticalSurface: '',
    location: '',
    center: {
      lat: '',
      lng: '',
    },
    wiseconnId: '',
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleCloseAndResetData = () => {
    setFarmInformation({
      name: '',
      theoreticalSurface: '',
      location: '',
      center: {
        lat: '',
        lng: '',
      },
    });
    setError({
      name: '',
      theoreticalSurface: '',
      location: '',
      center: {
        lat: '',
        lng: '',
      },
      wiseconnId: '',
    });
    setModalState({ open: false, toEdit: false });
    setOpenSecondModalFarmFlow(false);
  };

  useEffect(() => {
    if (modalState.toEdit && selectedFarm) {
      setFarmInformation(selectedFarm);
    }
  }, [modalState.toEdit, selectedFarm]);

  const { data: wiseconnIdsData } = useFetchApi<GetFarmsWiseconnIdsResponse>({
    modelEndpoint: 'wiseconnIdsList',
    modelName: 'farms',
  });

  const allFarmsWiseconnIds = wiseconnIdsData?.allFarmsWiseconnIds ?? [];

  const handleValueChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    source: string,
  ) => {
    const { value } = e.target;
    if (source === 'lng' || source === 'lat') {
      setFarmInformation((prevState) => ({
        ...prevState,
        center: { ...prevState.center, [source]: value },
      }));
    } else {
      setFarmInformation((prevState) => ({ ...prevState, [source]: value }));
    }
  };

  const handleStep = (step: string) => {
    if (step === 'next') {
      let counter = 0;
      const errorObj = {
        name: '',
        theoreticalSurface: '',
        location: '',
        center: {
          lat: '',
          lng: '',
        },
        wiseconnId: '',
      };

      const errorObjKeys = Object.keys(errorObj);

      Object.entries(farmInformation).forEach(([key, value]) => {
        const typedKey = key as InfoFarmKeys;

        if (typedKey === 'center') {
          const centerValue = value as FarmSchema['center'];
          if (!centerValue.lat || !centerValue.lng) {
            counter += 1;
            errorObj.center.lat = !centerValue.lat
              ? `${FARM_NAME_CONVERSIONS[typedKey]} (Lat) no puede estar vacio`
              : '';
            errorObj.center.lng = !centerValue.lng
              ? `${FARM_NAME_CONVERSIONS[typedKey]} (Lng) no puede estar vacio`
              : '';
          }
        } else if (value === '' && errorObjKeys.includes(typedKey)) {
          counter += 1;
          errorObj[typedKey] = `${FARM_NAME_CONVERSIONS[typedKey]} no puede estar vacio`;
        } else {
          errorObj[typedKey] = '';
        }
      });

      if (counter > 0) {
        setError(errorObj);
        return;
      }

      if (farmInformation.wiseconnId
        && allFarmsWiseconnIds.includes(farmInformation.wiseconnId) && !modalState.toEdit) {
        setError((prevState) => ({ ...prevState, wiseconnId: 'El Wiseconn ID ya está en uso' }));
        return;
      }

      setOpenSecondModalFarmFlow(true);
      setModalState((prev) => ({ ...prev, open: false }));
    } else {
      setOpenSecondModalFarmFlow(false);
      setModalState((prev) => ({ ...prev, open: true }));
    }
  };

  const handleCenterChange = (centerCoordinates: {
    lat: () => string;
    lng: () => string;
  }) => {
    if (centerCoordinates) {
      const auxLat = centerCoordinates.lat();
      const auxLng = centerCoordinates.lng();

      if (auxLat !== farmInformation.center.lat || auxLng !== farmInformation.center.lng) {
        setFarmInformation((prevState) => ({
          ...prevState,
          center: {
            lat: centerCoordinates.lat(),
            lng: centerCoordinates.lng(),
          },
        }));
      }
    }
  };

  const handleZoomChange = (zoom: number) => {
    setFarmInformation((prevState) => ({
      ...prevState,
      zoom,
    }));
  };

  const upsertFarmFunction = async (coordinates?: { lat: number, lng: number }[] | null) => {
    setButtonDisabled(true);

    try {
      if (modalState.toEdit && selectedFarm?.id && farmsOptions) {
        // Actualizar campo existente
        const farmObj: UpdateFarmSchema = {
          name: farmInformation.name,
          location: farmInformation.location,
          center: {
            lat: farmInformation.center.lat,
            lng: farmInformation.center.lng,
          },
          theoreticalSurface: farmInformation.theoreticalSurface,
          wiseconnId: farmInformation.wiseconnId,
          coordinates: coordinates ?? undefined,
        };

        const { data } = await apiClient.farms.farmsUpdate(selectedFarm.id, farmObj);

        dispatch(setAccountFarmsOptions(farmsOptions?.map((farm) => (farm.id === selectedFarm.id
          ? (data.farm as FarmSchema)
          : farm))));
        dispatch(setAccountFarm(data.farm as FarmSchema));
      } else {
        // Crear campo nuevo
        const farmCreationObj: PostFarmSchema = {
          name: farmInformation.name,
          location: farmInformation.location,
          center: {
            lat: farmInformation.center.lat,
            lng: farmInformation.center.lng,
          },
          zoom: farmInformation.zoom,
          theoreticalSurface: farmInformation.theoreticalSurface,
          accountDocumentId: selectedAccount?.id,
          wiseconnId: farmInformation.wiseconnId,
        };

        if (coordinates && Array.isArray(coordinates)) {
          farmCreationObj.coordinates = coordinates;
        }

        const { data } = await apiClient.farms.farmsCreate(farmCreationObj);

        dispatch(setAccountFarmsOptions(farmsOptions
          ? [...farmsOptions, (data.farm as FarmSchema)]
          : [(data.farm as FarmSchema)]));
        dispatch(setAccountFarm(data.farm as FarmSchema));
      }

      handleCloseAndResetData();
      enqueueSnackbar(modalState.toEdit ? 'Campo actualizado exitosamente' : 'Campo creado exitosamente',
        { variant: 'success' });
    } catch (err) {
      const { error: fetchError } = err as FetchError;
      if (fetchError?.message) {
        enqueueSnackbar(fetchError?.message, { variant: 'error' });
      } else {
        const auxError = err as Error;
        enqueueSnackbar(auxError.message, { variant: 'error' });
      }
    } finally {
      setButtonDisabled(false);
    }
  };

  return (
    <CustomModal
      open={modalState.open || openSecondModalFarmFlow}
      handleClose={handleCloseAndResetData}
      cardClassName={openSecondModalFarmFlow ? classes.cardModalContainer : undefined}
    >
      <>
        {modalState.open
          && <Box display='flex' flexDirection='column' flex='1'>
            <Box className={classes.uploadAddDevicesHeader}>
              <Typography
                variant="h3"
                color="textPrimary"
                className={classes.uploadAddDevicesHeaderTitle}
              >
                <span className={classes.activePageUnderlined}>
                  {modalState.toEdit ? 'Editar campo' : 'Agregar campo'}
                </span>
              </Typography>
              <IconButton onClick={handleCloseAndResetData}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.textFieldsContainer}>
              <TextField
                className={classes.singleLeftTextfield}
                label='Nombre (*)'
                variant='standard'
                fullWidth
                value={farmInformation.name}
                onChange={(e) => handleValueChange(e, 'name')}
                error={error.name !== ''}
                helperText={error.name !== '' && error.name}
              />
              <TextField
                label='Superficie teórica (hectáreas) (*)'
                variant='standard'
                fullWidth
                value={farmInformation.theoreticalSurface}
                onChange={(e) => handleValueChange(e, 'theoreticalSurface')}
                error={error.theoreticalSurface !== ''}
                helperText={error.theoreticalSurface !== '' && error.theoreticalSurface}
                InputProps={{ endAdornment: farmInformation.theoreticalSurface !== '' && <InputAdornment position="end">ha</InputAdornment> }}
              />
            </Box>
            <Box className={classes.textFieldsContainer}>
              <TextField
                label='Ubicación (*)'
                variant='standard'
                fullWidth
                value={farmInformation.location}
                onChange={(e) => handleValueChange(e, 'location')}
                error={error.location !== ''}
                helperText={error.location !== '' && error.location}
              />
            </Box>
            <Box className={classes.textFieldsContainer}>
              <TextField
                label='Latitud (*)'
                variant='standard'
                fullWidth
                className={classes.singleLeftTextfield}
                value={farmInformation.center.lat}
                onChange={(e) => handleValueChange(e, 'lat')}
                error={error.center.lat !== ''}
                helperText={error.center.lat !== '' && error.center.lat}
              />
              <TextField
                label='Longitud (*)'
                variant='standard'
                fullWidth
                className={classes.singleLeftTextfield}
                value={farmInformation.center.lng}
                onChange={(e) => handleValueChange(e, 'lng')}
                error={error.center.lng !== ''}
                helperText={error.center.lng !== '' && error.center.lng}
              />
            </Box>
            <Box className={classes.singleTextFieldsContainer}>
              <TextField
                label='Wiseconn ID (*)'
                variant='standard'
                fullWidth
                className={classes.singleLeftTextfield}
                value={farmInformation.wiseconnId}
                error={error.wiseconnId !== ''}
                helperText={error.wiseconnId !== '' && error.wiseconnId}
                onChange={(e) => handleValueChange(e, 'wiseconnId')}
              />
            </Box>
            <Box className={classes.buttonContainer}>
              <Box className={classes.singleButtonContainer} marginRight='20px'>
                <CustomButton buttonText='cancelar' onClick={handleCloseAndResetData} />
              </Box>
              <Box className={classes.singleButtonContainer}>
                <CustomButton buttonText='siguiente' onClick={() => handleStep('next')} />
              </Box>
            </Box>
          </Box>

        }
        {openSecondModalFarmFlow
          && <AddPolygons
            title={modalState.toEdit ? 'Editar polígono campo' : 'Agregar polígono campo'}
            detailedInfo={{
              Nombre: farmInformation.name,
              'Superficie Total': farmInformation.theoreticalSurface,
              Ubicación: farmInformation.location,
            }}
            detailedInfoTitle='Información del campo'
            handleCloseModal={handleCloseAndResetData}
            handleGoBack={() => handleStep('prev')}
            lat={farmInformation.center.lat as string}
            lng={farmInformation.center.lng as string}
            insertFunction={upsertFarmFunction}
            handleCenterChange={handleCenterChange}
            handleZoomChange={handleZoomChange}
            enforcePolygon
            buttonDisabled={buttonDisabled}
            isBeingUpdated={modalState.toEdit}
          />
        }
      </>
    </CustomModal>
  );
};

export default UpsertFarmModal;
