const SOIL_TYPES: Record<string, string> = {
  clay: 'Arcilloso',
  sandy: 'Arenoso',
  silty: 'Limoso',
  loamy: 'Franco',
  sandyLoam: 'Franco Arenoso',
  siltyLoam: 'Franco Limoso',
  clayLoam: 'Franco Arcilloso',
  sandyClayLoam: 'Franco Arcillo Arenoso',
  siltyClayLoam: 'Franco Arcillo Limoso',
  sandyClay: 'Arcillo Arenoso',
  siltyClay: 'Arcillo Limoso',
  loamySand: 'Areno Francoso',
};

export default SOIL_TYPES;
