import {
  getET0,
  getFilteredReadings,
  getAllDailyReadings,
  getLastIrrigationFromDate,
  calculateKc,
  et0ForKc,
} from '../dataServices';
import {
  ENDPOINT,
  buildCategories,
  getGranularity,
  mapDates,
  getWeeklyAverageData,
} from '../../../utils/highcharts';

const prepareData = async (filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sectorId?: string;
  wiseconnZoneId?: string;
}) => {
  const hourInterval = 24;
  const granularity = getGranularity(filters.startDate, filters.endDate);

  // if (granularity === 'hourly') {
  //   hourInterval = 1;
  // }

  // Categories for the chart (weekly)
  const chartCategories = buildCategories(granularity, filters.startDate, filters.endDate);

  // Categories for the kc calculation (daily)
  const categoriesForKcCalculation = buildCategories('daily', filters.startDate, filters.endDate);

  const lastIrrigationInfo = await getLastIrrigationFromDate(filters);
  const lastIrrigationDate = lastIrrigationInfo.lastNonZeroIrrigationDate;

  // Fetch all data in parallel
  const [irrigationData, et0Data, rainData] = await Promise.all([
    getAllDailyReadings({
      ...filters,
      name: 'Irrigation Precipitation',
    }),
    getET0({
      farmId: filters.farmId,
      startDate: lastIrrigationDate, // We'll update this after getting lastIrrigationInfo
      endDate: filters.endDate,
      endpoint: ENDPOINT.et0,
    }),
    getFilteredReadings({
      farmId: filters.farmId,
      startDate: filters.startDate,
      endDate: filters.endDate,
      sensorType: 'Rain',
      hourInterval,
      endpoint: ENDPOINT[granularity],
    }),
  ]);

  const irrigationDataMap = mapDates(irrigationData, granularity, 'maxValue');

  const normalizedIrrigationDataForKc = (Array.isArray(categoriesForKcCalculation)
    ? categoriesForKcCalculation : categoriesForKcCalculation.categories)
    .map((category: any) => irrigationDataMap.get(category) || 0);

  const normalizedIrrigationData = (Array.isArray(chartCategories)
    ? chartCategories : chartCategories.categories)
    .map((category: any) => irrigationDataMap.get(category) || 0);

  const { lastNonZeroIrrigationValue } = lastIrrigationInfo;

  let et0DataMap = mapDates(et0Data, granularity, 'et0');

  if (granularity === 'weekly') {
    et0DataMap = getWeeklyAverageData(et0DataMap as Map<string, number>);
  }

  const normalizedEt0Data = (Array.isArray(chartCategories)
    ? chartCategories : chartCategories.categories)
    .map((category: any) => et0DataMap.get(category) || 0);

  const { kcData } = await calculateKc({
    normalizedIrrigationData: normalizedIrrigationDataForKc,
    et0Data,
    lastNonZeroIrrigationValue,
  });

  let kcDataMap = mapDates(kcData, granularity, 'kc');

  if (granularity === 'weekly') {
    kcDataMap = getWeeklyAverageData(kcDataMap as Map<string, number>);
  }

  const normalizedKcData = (Array.isArray(chartCategories)
    ? chartCategories : chartCategories.categories)
    .map((category: any) => kcDataMap.get(category) || 0);

  // Create a mapping for `rainData` based on granularity
  const rainDataMap = mapDates(rainData, granularity, 'cumulativeTotal');

  const normalizedRainData = (Array.isArray(chartCategories)
    ? chartCategories : chartCategories.categories)
    .map((category: any) => rainDataMap.get(category) || 0);

  const et0DataForKc = et0ForKc(et0Data, normalizedIrrigationData);

  const normalizedEt0DataForKc = et0DataForKc.map((item: any) => item.et0);

  const cumulativeEt0 = normalizedEt0Data.map((_, index) => Number(normalizedEt0Data
    .slice(0, index + 1).reduce((acc: any, curr: any) => acc + curr, 0).toFixed(2)));

  return {
    et0: normalizedEt0Data, // Et0 per hour
    cumulativeEt0: granularity === 'weekly' ? cumulativeEt0 : normalizedEt0DataForKc,
    rain: normalizedRainData, // Rain per hour
    irrigation: normalizedIrrigationData, // Irrigation per day
    kc: normalizedKcData, // Kc per day
    categories: Array.isArray(chartCategories) ? chartCategories : chartCategories.categories,
    granularity,
  };
};

export default prepareData;
