import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import { ConversationSchema } from '../../server/schemas/apiTypes';
import { ScatterPoint } from '../components/Highcharts/types';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

export const ENDPOINT = {
  hourly: '/hourly',
  daily: '/daily',
  weekly: '/weekly',
  et0: '/daily',
};

function getGranularity(startDate: string, endDate: string): 'hourly' | 'daily' | 'weekly' {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  // Calculate difference in days
  const diffInDays = end.diff(start, 'day', true);

  if (diffInDays <= 1) {
    return 'hourly';
  }
  if (diffInDays > 33) {
    return 'weekly';
  }
  return 'daily';
}

function mapDates(data: any, granularity: 'hourly' | 'daily' | 'weekly', itemKey: string, dateKey = 'date') {
  return new Map(
    data.map((item: any) => {
      let key;

      // Determinar la clave basada en la granularidad
      if (granularity === 'hourly') {
        key = item[dateKey];
      } else {
        key = dayjs(item[dateKey]).utc().format('MMM DD');
      }
      // Retornar la pareja [clave, valor] para el Map
      return [key, item[itemKey]];
    }),
  );
}

function buildCategories(
  granularity: 'hourly' | 'daily' | 'weekly',
  startDate: string,
  endDate: string,
  hourInterval?: number,
  daysInterval?: number,
  isRoots?: boolean,
) {
  // Convert start/end dates to dayjs objects for easier manipulation
  const start = dayjs(startDate).utc();
  const end = dayjs(endDate).utc();
  const uniqueDates: string[] = [];
  const allDates: string[] = [];

  // Build array of dates based on granularity
  let current = start;

  if (granularity === 'weekly') {
    // Generate all dates between start and end only for weekly granularity
    let currentDate = start;
    while (currentDate.isSameOrBefore(end)) {
      allDates.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'day');
    }

    while (current.isSameOrBefore(end)) {
      uniqueDates.push(current.format('YYYY-MM-DD'));
      current = current.add(7, 'day');
    }
  } else {
    while (current.isSameOrBefore(end)) {
      uniqueDates.push(current.format('YYYY-MM-DD'));
      current = current.add(1, 'day');
    }
  }

  // Special handling for hourly data with intervals
  if (granularity === 'daily' && hourInterval) {
    const intervalsPerDay = 24 / hourInterval;
    const emptyLabels = Array(intervalsPerDay - 1).fill('');

    // Pre-generate hour intervals once
    const hours = Array.from(
      { length: intervalsPerDay },
      (_, i) => ({
        start: `${(i * hourInterval).toString().padStart(2, '0')}:00`,
        end: `${((i + 1) * hourInterval).toString().padStart(2, '0')}:00`,
      }),
    );

    return {
      categories: uniqueDates.flatMap((date) => [
        dayjs(date).utc().format('MMM DD'),
        ...emptyLabels,
      ]),
      categoriesByIndex: uniqueDates.flatMap((date) => [
        date,
        ...Array(intervalsPerDay - 1).fill(date),
      ]),
      intervalStart: uniqueDates.flatMap(() => hours.map((h) => h.start)),
      intervalEnd: uniqueDates.flatMap(() => hours.map((h) => h.end)),
    };
  }

  // Special handling for weekly data with intervals
  if (granularity === 'weekly' && daysInterval) {
    const daysPerInterval = 7;
    const emptyLabels = Array(daysPerInterval - 1).fill('');

    // Pre-generate week intervals
    const weekIntervals = uniqueDates.map((date) => {
      const startWeekDay = dayjs(date);
      return {
        start: startWeekDay.format('MMM DD'),
        end: startWeekDay.format('MMM DD'),
      };
    });

    return {
      categories: uniqueDates.flatMap((date) => [
        dayjs(date).utc().format('MMM DD'),
        ...emptyLabels,
      ]),
      categoriesByIndex: allDates,
      intervalStart: uniqueDates.flatMap(() => Array(daysPerInterval).fill(weekIntervals[0].start)),
      intervalEnd: uniqueDates.flatMap(() => Array(daysPerInterval).fill(weekIntervals[0].end)),
    };
  }

  // For non-interval cases, format dates based on granularity
  if (granularity === 'hourly') {
    // Return array of hours from 00:00 to 23:00
    const categories = Array.from({ length: 24 }, (_, i) => `${i.toString().padStart(2, '0')}:00`);
    return categories;
  }

  if (isRoots) {
    return uniqueDates.map((date) => dayjs(date).utc().format('MMM DD YYYY'));
  }

  return uniqueDates.map((date) => dayjs(date).utc().format('MMM DD'));
}

interface WeeklyDataEntry {
  date: string;
  startWeekDay: string;
  weekEnd: string;
  weekAverage: number;
}

function getWeeklyAverageData(data: Map<string, number>): Map<string, number> {
  const weeklyData: WeeklyDataEntry[] = [];
  const entries = Array.from(data.entries());

  for (let i = 0; i < entries.length; i += 7) {
    const weekEntries = entries.slice(i, i + 7);
    if (weekEntries.length > 0) {
      const startWeekDay = weekEntries[0][0];
      const weekEnd = weekEntries[weekEntries.length - 1]?.[0] || startWeekDay;

      const sum = weekEntries.reduce((acc, [, value]) => acc + value, 0);
      const average = sum / weekEntries.length;

      weeklyData.push({
        date: startWeekDay,
        startWeekDay,
        weekEnd,
        weekAverage: Number(average.toFixed(2)),
      });
    }
  }
  const response = mapDates(weeklyData, 'weekly', 'weekAverage') as Map<string, number>;
  return response;
}

function getWeekInterval(category: string) {
  const startDate = dayjs(category);
  const endDate = startDate.add(6, 'day');
  return `${startDate.format('MMM DD')} - ${endDate.format('MMM DD')}`;
}

function getTimePoint(pointIndex: number, absoluteCategories: string[]) {
  if (!absoluteCategories[pointIndex]) return null;

  const category = absoluteCategories[pointIndex];

  // Intenta primero el formato ISO (2025-01-09)
  const isoDateComponents = category.match(/^(\d{4})-(\d{2})-(\d{2})/);

  if (isoDateComponents) {
    const [, year, month, day] = isoDateComponents;
    const timeComponents = category.match(/(\d{2}):(\d{2})$/);

    if (timeComponents) {
      const [, hour] = timeComponents;
      return {
        year,
        month,
        day,
        hour: `${hour}:00`,
      };
    }

    return {
      year,
      month,
      day,
    };
  }

  // Si no es formato ISO, intenta el formato MMM DD YYYY
  const altDateComponents = category.match(/([A-Za-z]+)\s+(\d{1,2})\s+(\d{4})/);
  if (altDateComponents) {
    const [, monthStr, day, year] = altDateComponents;
    const month = (dayjs(`${monthStr} 1`).month() + 1).toString().padStart(2, '0');

    return {
      year,
      month,
      day: day.padStart(2, '0'),
    };
  }

  return null;
}

// Creo que acá hay que hacer el agroup en caso de que haya varios comentarios en el mismo punto
const getScatterConversations = (
  conversations: Array<ConversationSchema>,
  granularity: 'hourly' | 'daily' | 'weekly',
  absoluteCategories: Array<string>,
  yAxisRange: { min: number, max: number },
  graphName?: string,
): ScatterPoint[] => {
  if (!conversations || !absoluteCategories.length) return [];

  if (graphName === 'Gráfico de Profundidad de Riego') {
    const scatterConversations = conversations
      .map((conversation) => {
        const { timePoint } = conversation;

        if (!timePoint.day || !timePoint.month || !timePoint.year) return null;
        const searchDate = dayjs(`${timePoint.year}-${timePoint.month}-${timePoint.day}`)
          .format('MMM DD YYYY');

        // Encontrar el índice en absoluteCategories
        const categoryIndex = absoluteCategories.findIndex(
          (category) => category === searchDate,
        );
        if (categoryIndex === -1) return null;

        return {
          x: categoryIndex,
          y: yAxisRange.min + (yAxisRange.max - yAxisRange.min) * 0.5,
          custom: {
            conversationId: conversation.conversationId,
            timePointData: timePoint,
          },
        };
      })
      .filter((point) => point !== null);

    return scatterConversations as ScatterPoint[];
  }

  const scatterConversations = conversations
    .map((conversation) => {
      const { timePoint } = conversation;
      let searchDate: string;

      if (granularity === 'hourly' || granularity === 'daily') {
        // Para hourly y daily necesitamos todos los campos
        if (!timePoint.hour || !timePoint.day || !timePoint.month || !timePoint.year) return null;
        searchDate = `${timePoint.year}-${timePoint.month}-${timePoint.day} ${timePoint.hour}`;
      } else { // cambiar a else if cuando se agregue el formato monthly
        // Para weekly necesitamos tres de los campos
        if (!timePoint.day || !timePoint.month || !timePoint.year) return null;
        searchDate = `${timePoint.year}-${timePoint.month}-${timePoint.day}`;
      }

      // Encontrar el índice en absoluteCategories
      const categoryIndex = absoluteCategories.findIndex(
        (category) => category === searchDate,
      );
      if (categoryIndex === -1) return null;

      return {
        x: categoryIndex,
        y: yAxisRange.min + (yAxisRange.max - yAxisRange.min) * 0.5,
        custom: {
          conversationId: conversation.conversationId,
          timePointData: timePoint,
        },
      };
    })
    .filter((point) => point !== null);

  return scatterConversations as ScatterPoint[];
};

export {
  getGranularity,
  buildCategories,
  mapDates,
  getWeeklyAverageData,
  getWeekInterval,
  getTimePoint,
  getScatterConversations,
};
