import React from 'react';
import {
  Box,
  Container,
} from '@mui/material';
import { useJsApiLoader } from '@react-google-maps/api';
import Page from '../../../components/Layout/Page';
import useStyles from './styles';
import CustomHeader from '../../../components/General/CustomHeader';
import SectorsList from '../Layout/component/Sector/SectorsList';
import useFetchFarms from '../../../hooks/useFetchFarms';
import AccountHomeMap from './components/AccountHomeMap';
import WeatherTabs from './components/WeatherTabs';

const AVAILABLE_LIBRARIES: ('drawing' | 'places')[] = ['drawing', 'places'];

const AccountHome = () => {
  const classes = useStyles({});
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '';

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
    libraries: AVAILABLE_LIBRARIES,
  });

  useFetchFarms();
  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Page className={classes.root} title='Inicio'>
      <Container maxWidth={false} className={classes.containerContainer}>
        <CustomHeader title='Inicio'/>
        <Box height='100%' display='flex' pt={3}>
          <Box marginRight='24px' className={classes.lateralListsContainer}>
            <SectorsList />
          </Box>
          <Box display='flex' flexDirection='column' flex={1}>
            <AccountHomeMap />
            <WeatherTabs />
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

export default AccountHome;
