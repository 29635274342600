import React from 'react';
import IrrigationChart from '../../../../../../components/Highcharts/IrrigationChart/IrrigationChart';

const IrrigationGraphsTab = ({
  sectorId, farmId, wiseconnZoneId,
}: { sectorId: string, farmId: string, wiseconnZoneId: string | undefined }) => (
    <>
      <IrrigationChart
        hideFilter={true}
        sectorId={sectorId}
        farmId={farmId}
        wiseconnZoneId={wiseconnZoneId}
        height={400}
      />
    </>
);

export default IrrigationGraphsTab;
