import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, CircularProgress } from '@mui/material';
import BaseHighchart from '../BaseHighchart';
import prepareData from './builder';

import {
  ChartDataType,
} from '../types';

import { ReduxState } from '../../../types';
import ChartFilter from '../ChartFilter';
import { navigateToGraph } from '../../../utils/helpers';
import apiClient from '../../../requests/api/apiClient';
import { ConversationSchema, TimePointSchema } from '../../../requests/api/apiTypes';
import { ROOTS_NAME } from '../../../constants/graphs';
import { FetchError } from '../../../views/types';
import ConversationModal from '../ConversationTab/conversationModal';

function RootsChart({
  sectorId,
  farmId,
  wiseconnZoneId,
  hideFilter = false,
  hideLegend = false,
  hideXValues = false,
  showIcons = true,
  hideYValues = false,
  height = 450,
  title = 'Gráfico de Profundidad de Riego',
  yAxisTitle = 'Profundidad (cm)',
  isDashboardChart = false,
  thresholds = undefined,
  externalModalState,
  externalSetModalState,
  externalConversations,
  externalSetConversations,
  breadcrumb,
}: {
  sectorId: string
  farmId: string
  wiseconnZoneId: string | undefined
  hideFilter?: boolean
  showIcons?: boolean
  height?: number
  hideLegend?: boolean
  hideXValues?: boolean
  hideYValues?: boolean
  yAxisTitle?: string
  title?: string
  isDashboardChart?: boolean
  thresholds?: {
    irrigationThreshold: number;
    rootsThreshold: number;
  }
  externalModalState?: {
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  };
  externalSetModalState?: React.Dispatch<React.SetStateAction<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>>;
  externalConversations?: ConversationSchema[];
  externalSetConversations?: React.Dispatch<React.SetStateAction<ConversationSchema[]>>;
  breadcrumb?: string;
}) {
  const [chartData, setChartData] = useState<ChartDataType>({
    series: [],
    categories: [],
    absoluteCategories: [],
  });
  const [irrigationTimes, setIrrigationTimes] = useState<string[]>([]);
  const [internalThresholds, setInternalThresholds] = useState<{
    irrigationThreshold: number | undefined;
    rootsThreshold: number | undefined;
  }>({
    irrigationThreshold: undefined,
    rootsThreshold: undefined,
  });
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [maxDetectionValue, setMaxDetectionValue] = useState<number>(0);
  const [internalModalState, setInternalModalState] = useState<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>({
    open: false,
    conversationId: '',
    timePointData: {
      hour: '',
      day: '',
      month: '',
      year: '',
    },
  });
  const [internalConversations, setInternalConversations] = useState<ConversationSchema[]>([]);

  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);
  const { enqueueSnackbar } = useSnackbar();

  const modalState = externalModalState || internalModalState;
  const setModalState = externalSetModalState || setInternalModalState;
  const conversations = externalConversations || internalConversations;
  const setConversations = externalSetConversations || setInternalConversations;

  const preparedFilters = useMemo(() => ({
    farmId,
    startDate,
    endDate,
    wiseconnZoneId,
  }), [farmId, startDate, endDate, wiseconnZoneId]);

  useEffect(() => {
    const loadInitialConfig = async () => {
      if (
        thresholds?.irrigationThreshold !== undefined
        && thresholds?.rootsThreshold !== undefined
      ) {
        setInternalThresholds(thresholds);
      } else if (sectorId) {
        try {
          const config = await apiClient.rootsConfig.rootsConfigDetail(sectorId);
          setInternalThresholds({
            irrigationThreshold: config.data.rootsConfig?.irrigationThreshold || 0.4,
            rootsThreshold: config.data.rootsConfig?.rootsThreshold || 0.25,
          });
        } catch (error) {
          setInternalThresholds({
            irrigationThreshold: 0.4,
            rootsThreshold: 0.25,
          });
        }
      }
    };

    loadInitialConfig();
  }, [sectorId, thresholds]);

  const createSeriesData = useCallback((preparedData: any) => [
    {
      name: 'Profundidad de riego',
      type: 'bar',
      data: preparedData.irrigationSeries,
      threshold: 0,
      marker: {
        enabled: false,
      },
      color: '#2374BF',
    },
    {
      name: 'Profundidad de raíces',
      type: 'bar',
      data: preparedData.rootsSeries,
      threshold: 0,
      marker: {
        enabled: false,
      },
      color: '#D2B48C', // Light brown color
    },
  ], []);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const preparedData = await prepareData({
        ...preparedFilters,
        irrigationThreshold: internalThresholds.irrigationThreshold,
        rootsThreshold: internalThresholds.rootsThreshold,
      });

      // Calculate max detection value
      const maxDetection = Math.max(
        Math.max(...preparedData.rootsSeries),
        Math.max(...preparedData.irrigationSeries),
      );
      setMaxDetectionValue(maxDetection + 10);

      // Extract irrigation times from irrigationDetection and filter out undefined values
      const times = preparedData.irrigationDetection
        .map((item) => item.irrigationTime)
        .filter((time): time is string => time !== undefined);
      setIrrigationTimes(times);

      const seriesData = createSeriesData(preparedData);

      setChartData({
        series: seriesData,
        categories: preparedData.categories as string[],
        absoluteCategories: preparedData.absoluteCategories,
      });
    } finally {
      setIsLoading(false);
    }
  }, [preparedFilters, internalThresholds, createSeriesData]);

  useEffect(() => {
    if (
      internalThresholds.irrigationThreshold !== undefined
      && internalThresholds.rootsThreshold !== undefined
    ) {
      fetchData();
    }
  }, [internalThresholds, fetchData]);

  const customTooltip = useMemo(() => ({
    shared: true,
    useHTML: true,
    formatter(
      this: Highcharts.TooltipFormatterContextObject,
    ) {
      const pointIndex = this.points?.[0].point.index;
      const category = chartData.categories[pointIndex as number];
      const header = `<div style="font-size: 12px; margin-bottom: 8px;">${category}</br></div>`;
      const points = this.points?.map(
        (point) => {
          const displayValue = Math.round(Number(point.y));
          const irrigationTime = irrigationTimes[point.point.index] || 'N/A';
          const showIrrigationTime = point.series.name.toLowerCase() === 'profundidad de riego';
          return `
            <div style="margin-bottom: 8px;">
              <span style="color:${point.color}">\u25CF</span> 
              <span>${point.series.name}: <b>${displayValue} cm</b></span>
              ${showIrrigationTime && irrigationTime !== 'N/A' ? `<span style="font-size: 10px;"><br/>Hora de riego: ${irrigationTime}</span>` : ''}
            </div>`;
        },
      ).join('');
      return `
        <div style="
          padding: 10px;
          background: rgba(255, 255, 255, 1);
          border-radius: 5px;">
          ${header}${points}
        </div>
      `;
    },
  }), [chartData.categories, irrigationTimes]);

  const chartConfig = useMemo(() => ({
    chart: {
      height,
    },
    yAxis: {
      reversed: true,
      min: 0,
      max: maxDetectionValue,
      gridLineWidth: 1,
      title: {
        text: '',
      },
    },
    xAxis: {
      categories: hideXValues ? [] : chartData.categories,
      plotBands: chartData.categories.map((_, index) => ({
        color: index % 2 === 0 ? 'rgba(0, 0, 0, 0.02)' : 'rgba(173, 216, 230, 0.3)',
        from: index - 0.5,
        to: index + 0.5,
      })),
      labels: {
        enabled: !hideXValues,
      },
    },
  }), [height, maxDetectionValue, hideXValues, chartData.categories]);

  useEffect(() => {
    if (!externalConversations) {
      const loadConversations = async () => {
        try {
          const { data } = await apiClient.conversation.conversationDetail(sectorId, ROOTS_NAME);
          setInternalConversations(data.conversations);
        } catch (err) {
          const { error } = err as FetchError;
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      };

      loadConversations();
    }
  }, [sectorId, externalConversations]);

  const handleConfigClick = () => {
    navigateToGraph('profundidad-riego', history, {
      farmId,
      sectorId,
      wiseconnZoneId: wiseconnZoneId || '',
      breadcrumb: breadcrumb || '',
    });
  };

  return (
    <>
      <Box>
        {isLoading && (
          <Box display="flex" justifyContent="center" my={2}>
            <CircularProgress />
          </Box>
        )}
        <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
          <BaseHighchart
            isDashboardChart={isDashboardChart}
            title={title}
            categories={chartData.categories}
            yAxisTitle={yAxisTitle}
            series={chartData.series as Highcharts.SeriesOptionsType[]}
            tooltip={customTooltip}
            hideFilter={hideFilter}
            showFullScreen={true}
            filter={<ChartFilter sectorId={sectorId} />}
            legend={{ enabled: !hideLegend }}
            chartConfig={chartConfig}
            hideXValues={hideXValues}
            onConfigClick={handleConfigClick}
            showIcons={showIcons}
            hideYValues={hideYValues}
            setModalState={setModalState}
            conversations={conversations}
            absoluteCategories={chartData.absoluteCategories}
            height={height}
            />
        </div>
      </Box>
      <ConversationModal
        modalState={modalState}
        setModalState={setModalState}
        farmId={farmId}
        sectorId={sectorId}
        chart={ROOTS_NAME}
        setConversationsFunction={setConversations}
      />
    </>
  );
}

export default RootsChart;
