import dayjs from 'dayjs';
import {
  fetchFilteredReadingsFromAPI,
  fetchAllReadingsFromAPI,
} from '../../services/readingsService';
import {
  calculateEsTmax,
  calculateEsTmin,
  calculateEa,
  calculateRnl,
  calculateRns,
  calculateRn,
  calculateEs,
  calculateS,
  calculateET0,
} from './formulas';

// To Do: Actualizar types
export const getFilteredReadings = async (filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sensorType: string;
  wiseconnZoneId?: string;
  sectorId?: string;
  hourInterval?: number;
  daysInterval?: number;
  endpoint?: string;
}) => {
  // Fetch readings from API
  const readings = await fetchFilteredReadingsFromAPI({
    farmId: filters.farmId,
    startDate: filters.startDate,
    endDate: filters.endDate,
    wiseconnZoneId: filters.wiseconnZoneId,
    sensorType: filters.sensorType,
    sectorId: filters.sectorId,
    hourInterval: filters.hourInterval,
    daysInterval: filters.daysInterval,
    endpoint: filters.endpoint,
  });

  return readings;
};

// The difference from getFilteredReadings is that this function returns
// all the readings for day without removing duplicates (same _id),
// and it's used for Irrigation Chart
export const getAllDailyReadings = async (filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sensorType?: string;
  wiseconnZoneId?: string;
  sectorId?: string;
  hourInterval?: number;
  name?: string;
  individual?: boolean;
}) => {
  // Fetch readings from API
  const readings = await fetchAllReadingsFromAPI({
    farmId: filters.farmId,
    startDate: filters.startDate,
    endDate: filters.endDate,
    wiseconnZoneId: filters.wiseconnZoneId,
    sensorType: filters.sensorType,
    sectorId: filters.sectorId,
    hourInterval: filters.hourInterval,
    name: filters.name,
    individual: filters.individual,
  });

  return readings;
};

export const getET0 = async (filters: {
  farmId: string;
  startDate: string;
  endDate: string;
  sectorId?: string;
  wiseconnZoneId?: string;
  endpoint?: string;
  hourInterval?: number;
  granularity?: string;
}) => {
  // Este update en el endpoint es por que no se calcula el et0 por semana, se va a
  // calcular el promedio de la semana pero obteniendo el et0 diario.
  // Ejecutamos todas las llamadas necesarias concurrentemente
  const [
    minAndMaxTemperature,
    minAndMaxHumidity,
    hourlySolarRadiation,
    hourlyWindVelocity,
  ] = await Promise.all([
    getFilteredReadings({
      ...filters,
      sensorType: 'Temperature',
      endpoint: filters.endpoint,
      hourInterval: 24,
    }),
    getFilteredReadings({
      ...filters,
      sensorType: 'Humidity',
      endpoint: filters.endpoint,
      hourInterval: 24,
    }),
    getFilteredReadings({
      ...filters,
      sensorType: 'Solar Radiation',
      endpoint: filters.endpoint,
      hourInterval: 24,
    }),
    getFilteredReadings({
      ...filters,
      sensorType: 'Wind Velocity',
      endpoint: filters.endpoint,
      hourInterval: 24,
    }),
  ]);

  const calculatedET0 = minAndMaxTemperature.map((item: any, index: number) => {
    const maxTemperature = item.maxValue;
    const minTemperature = item.minValue;
    const maxHumidity = minAndMaxHumidity[index].maxValue;
    const minHumidity = minAndMaxHumidity[index].minValue;
    const solarRadiation = hourlySolarRadiation[index].averageValue;
    const windVelocity = hourlyWindVelocity[index].averageValue;
    // Validamos que todos los valores estén presentes
    if (
      maxTemperature === undefined
      || minTemperature === undefined
      || maxHumidity === undefined
      || minHumidity === undefined
      || solarRadiation === undefined
      || windVelocity === undefined
    ) {
      // To Do: Ver que hacer con los datos faltantes
      return 0; // Si falta algún dato, asignamos ET0 como 0
    }

    // Cálculos intermedios
    const esTmax = calculateEsTmax(maxTemperature);
    const esTmin = calculateEsTmin(minTemperature);
    const ea = calculateEa(esTmax, esTmin, minHumidity, maxHumidity);
    const rns = calculateRns(solarRadiation);
    const rnl = calculateRnl(maxTemperature, minTemperature, ea, solarRadiation);
    const rn = calculateRn(rns, rnl);
    const es = calculateEs(esTmax, esTmin);
    const averageTemperature = (maxTemperature + minTemperature) / 2;
    const s = calculateS(es, averageTemperature);

    // Cálculo final de ET0
    return calculateET0(s, rn, averageTemperature, windVelocity, es, ea);
  });

  // Calcular el acumulado progresivo de ET0
  let cumulativeET0 = 0;
  const accumulatedEt0 = calculatedET0.map((et0: number, index: number) => {
    cumulativeET0 += et0;
    let date;
    if (filters.endpoint === '/daily') {
      date = minAndMaxTemperature[index].date;
    } else if (filters.endpoint === '/hourly') {
      date = minAndMaxTemperature[index].hour;
    } else {
      date = minAndMaxTemperature[index].date;
    }
    return {
      et0,
      cumulativeET0,
      date,
    };
  });

  return accumulatedEt0;
};

export const getLastIrrigationFromDate = async (filters: {
  farmId: string;
  wiseconnZoneId?: string;
  sectorId?: string;
  startDate: string;
  endDate: string;
}) => {
  let lastNonZeroIrrigationDate = filters.startDate;
  let lastNonZeroIrrigationValue = 0;

  const previousData = await getAllDailyReadings({
    ...filters,
    name: 'Irrigation Precipitation',
    startDate: dayjs(filters.startDate).subtract(30, 'days').toISOString(), // Search up to 30 days back
    endDate: filters.startDate,
  });

  // Find most recent non-zero irrigation value
  const lastNonZeroIrrigation = previousData.reverse().find((item: any) => item.maxValue > 0);
  if (lastNonZeroIrrigation) {
    lastNonZeroIrrigationDate = dayjs.utc(lastNonZeroIrrigation.date).startOf('day').toISOString();
    lastNonZeroIrrigationValue = lastNonZeroIrrigation.maxValue;
  }

  return {
    lastNonZeroIrrigationDate,
    lastNonZeroIrrigationValue,
  };
};

export const et0ForKc = (et0Data: any, irrigationDataForKc: any) => {
  let et0Accumulated = 0;
  const et0DataForKc: any = [];

  for (let i = 0; i < irrigationDataForKc.length; i += 1) {
    if (Number(irrigationDataForKc[i]) !== 0) {
      et0Accumulated = et0Data[i].et0;
      et0DataForKc.push({ et0: et0Accumulated, date: et0Data[i].date });
    } else {
      et0Accumulated += et0Data[i].et0;
      et0DataForKc.push({ et0: et0Accumulated, date: et0Data[i].date });
    }
  }

  return et0DataForKc;
};

export const calculateKc = async ({
  normalizedIrrigationData,
  et0Data,
  lastNonZeroIrrigationValue,
}: {
  normalizedIrrigationData: any;
  et0Data: any;
  lastNonZeroIrrigationValue: number;
}) => {
  let missingIrrigationLength = 0;

  const irrigationDataForKc = [...normalizedIrrigationData];

  // Esta condicion quiere decir que hay que usar un riego previo
  if (et0Data.length > normalizedIrrigationData.length) {
    missingIrrigationLength = et0Data.length - normalizedIrrigationData.length;

    if (missingIrrigationLength > 0) {
      for (let i = 0; i < missingIrrigationLength - 1; i += 1) {
        irrigationDataForKc.unshift(0);
      }
      irrigationDataForKc.unshift(lastNonZeroIrrigationValue);
    }
  } else {
    missingIrrigationLength = normalizedIrrigationData.length - et0Data.length;

    if (missingIrrigationLength > 0) {
      for (let i = 0; i < missingIrrigationLength; i += 1) {
        if (normalizedIrrigationData[i] === 0) {
          et0Data.unshift({ et0: 0, cumulativeET0: 0, date: '' });
        } else {
          et0Data.push({ et0: 0, cumulativeET0: 0, date: '' });
        }
      }
    }
  }

  // Construir los datos de et0 alineados con irrigación
  const et0DataForKc = et0ForKc(et0Data, irrigationDataForKc);

  // Reemplazar ceros en irrigación con el último valor no nulo
  let lastNonZero = irrigationDataForKc[0];
  for (let i = 0; i < irrigationDataForKc.length; i += 1) {
    if (irrigationDataForKc[i] === 0) {
      irrigationDataForKc[i] = lastNonZero;
    } else {
      lastNonZero = irrigationDataForKc[i];
    }
  }

  // Calcular Kc
  const kcData = irrigationDataForKc.map((irrigation: any, index: number) => {
    const et0Value = et0DataForKc[index].et0;
    return {
      kc: Number(irrigation) / et0Value,
      date: et0DataForKc[index].date,
      startWeekDay: et0DataForKc[index].date,
    };
  });

  // Normalizar Kc eliminando los elementos agregados anteriormente
  return {
    irrigationDataForKc,
    et0DataForKc,
    kcData, // Devuelve los datos normalizados de Kc
  };
};
