import dayjs from 'dayjs';
import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { CircularProgress, Box } from '@mui/material';
import BaseHighchart from '../BaseHighchart';
import prepareData from '../StackedSoilMoistureChart/builder';
import { navigateToGraph } from '../../../utils/helpers';
import apiClient from '../../../requests/api/apiClient';

import {
  ChartDataType,
  ChartFilters,
  ScatterPoint,
} from '../types';

import type PreparedData from './types';
import { ReduxState } from '../../../types';
import ChartFilter from '../ChartFilter';
import { ConversationSchema, TimePointSchema } from '../../../requests/api/apiTypes';
import ConversationModal from '../ConversationTab/conversationModal';
import { FetchError } from '../../../views/types';
import { SOIL_MOISTURE_NAME } from '../../../constants/graphs';

function SoilMoistureChart({
  sectorId,
  farmId,
  wiseconnZoneId,
  hideFilter = true,
  hideYValues = false,
  hideLegend = false,
  hideXValues = false,
  showIcons = true,
  height = 450,
  title = 'Gráfico de humedad suelo',
  yAxisTitle = 'Humedad Suelo Acumulada (%)',
  isDashboardChart = false,
  selectedDepths = undefined,
  externalModalState,
  externalSetModalState,
  externalConversations,
  externalSetConversations,
  configuredAreas = undefined,
  breadcrumb,
}: {
  sectorId: string
  farmId: string
  wiseconnZoneId: string | undefined
  hideFilter?: boolean
  hideYValues?: boolean
  hideLegend?: boolean
  hideXValues?: boolean
  showIcons?: boolean
  height?: number
  title?: string
  yAxisTitle?: string
  isDashboardChart?: boolean
  selectedDepths?: string[]
  externalModalState?: {
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  };
  externalSetModalState?: React.Dispatch<React.SetStateAction<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>>;
  externalConversations?: ConversationSchema[];
  externalSetConversations?: React.Dispatch<React.SetStateAction<ConversationSchema[]>>;
  configuredAreas?: any[]
  breadcrumb?: string
}) {
  const [chartData, setChartData] = useState<ChartDataType>({
    series: [],
    categories: [],
    categoriesByIndex: [],
    absoluteCategories: [],
  });
  const [yAxisRange, setYAxisRange] = useState({ min: 0, max: 100 });
  const [isLoading, setIsLoading] = useState(true);

  const [internalSelectedDepths, setInternalSelectedDepths] = useState<string[]>([]);
  const [internalModalState, setInternalModalState] = useState<{
    open: boolean;
    conversationId: string;
    timePointData: TimePointSchema;
  }>({
    open: false,
    conversationId: '',
    timePointData: {
      hour: '',
      day: '',
      month: '',
      year: '',
    },
  });
  const [internalConversations, setInternalConversations] = useState<ConversationSchema[]>([]);
  const [areas, setAreas] = useState<any[]>([]);
  const [plotBands, setPlotBands] = useState<any[]>([]);
  const [plotLines, setPlotLines] = useState<any[]>([]);

  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const modalState = externalModalState || internalModalState;
  const setModalState = externalSetModalState || setInternalModalState;
  const conversations = externalConversations || internalConversations;
  const setConversations = externalSetConversations || setInternalConversations;

  const defaultArea = {
    from: 0,
    to: 1000,
    color: 'rgba(128, 245, 192, 0.5)',
    label: {
      text: '',
      align: 'right',
      verticalAlign: 'top',
      x: -10,
      y: 20,
    },
  };

  const getAreaColor = (areaType: string) => {
    switch (areaType) {
      case 'blue':
        return 'rgba(127, 192, 255, 0.4)';
      case 'green':
        return 'rgba(128, 245, 192, 0)';
      case 'red':
        return 'rgba(255, 192, 192, 0.3)';
      case 'yellow':
        return 'rgba(255, 255, 0, 0.3)';
      default:
        return 'rgba(128, 255, 192, 0.4)';
    }
  };

  const generatePlotBands = useCallback(() => {
    if (areas.length === 0) {
      return {
        plotBands: [defaultArea],
        plotLines: [{
          value: defaultArea.to,
          color: '#000000',
          width: 1,
          zIndex: 0,
        }],
      };
    }

    const blueArea = areas.find((area) => area.areaType === 'blue');
    const yellowArea = areas.find((area) => area.areaType === 'yellow');
    const redArea = areas.find((area) => area.areaType === 'red');

    const updatedDefaultArea = {
      ...defaultArea,
      from: (() => {
        if (yellowArea) return yellowArea.to;
        if (redArea) return redArea.to;
        return 0;
      })(),
      to: blueArea ? blueArea.from : 1000,
    };

    const bands = [
      updatedDefaultArea,
      ...areas?.map((area) => ({
        from: area.from,
        to: area.to,
        color: getAreaColor(area.areaType),
        label: {
          text: area.label,
          style: {
            fontSize: '12px',
            color: '#666',
          },
          align: 'right',
          verticalAlign: area.areaType === 'blue' ? 'bottom' : 'top',
          x: -10,
          y: -5,
        },
      })),
    ];

    const lines = bands.map((band) => ({
      value: band.to,
      color: '#000000',
      width: 1,
      zIndex: 1,
    }));

    return {
      plotBands: bands,
      plotLines: lines,
    };
  }, [areas]);

  // Update plotBands whenever areas change
  useEffect(() => {
    const { plotBands: newPlotBands, plotLines: newPlotLines } = generatePlotBands();
    setPlotBands(newPlotBands);
    setPlotLines(newPlotLines);
  }, [areas, generatePlotBands]);

  useEffect(() => {
    const loadInitialConfig = async () => {
      if (selectedDepths === undefined && configuredAreas === undefined && sectorId) {
        try {
          const config = await apiClient.soilMoistureConfig.soilMoistureConfigDetail(sectorId);
          setInternalSelectedDepths(config.data.soilMoistureConfig?.levels || []);
          setAreas(config.data.soilMoistureConfig?.areas || []);
        } catch (error) {
          setInternalSelectedDepths([]);
          setAreas([]);
        }
      } else {
        setInternalSelectedDepths(selectedDepths || []);
        setAreas(configuredAreas || []);
      }
    };

    loadInitialConfig();
  }, [wiseconnZoneId, selectedDepths, configuredAreas, sectorId]);

  const calculateSummedSeries = useCallback((data: PreparedData, depthsToSum: string[]) => {
    const depthSeries = data.series.filter(
      (item: { name: string; data: number[] }) => depthsToSum.includes(item.name),
    );
    const summedData = depthSeries.reduce(
      (acc: number[], curr: { name: string; data: number[] }) => {
        curr.data.forEach((value: number, index: number) => {
          acc[index] = (acc[index] || 0) + value;
        });
        return acc;
      },
      [],
    );
    return summedData.map((y: number, x: number) => ({
      x,
      y,
    }));
  }, []);

  const calculateYAxisRange = useCallback((summedData: { x: number; y: number }[]) => {
    const values = summedData.map((point) => point.y);
    const dataMin = Math.min(...values);
    const dataMax = Math.max(...values);

    const redArea = areas.find((area) => area.areaType === 'red');
    const blueArea = areas.find((area) => area.areaType === 'blue');
    const greenAreas = areas.filter((area) => area.areaType === 'green');
    const yellowArea = areas.find((area) => area.areaType === 'yellow');

    let min = dataMin;
    let max = dataMax;

    // Check red area for min
    if (redArea) {
      min = Math.min(redArea.to, min);
    }

    if (yellowArea) {
      min = Math.min(yellowArea.to, min);
    }

    // Check blue area for max
    if (blueArea) {
      max = Math.max(blueArea.from, max);
    }

    // Check all green areas for both min and max
    greenAreas.forEach((greenArea) => {
      min = Math.min(greenArea.from, min);
      max = Math.max(greenArea.to, max);
    });

    // Add padding of 2 units if data extends beyond areas
    if (dataMin < min) min = dataMin;
    if (dataMax > max) max = dataMax;

    return {
      min,
      max,
    };
  }, [areas]);

  const fetchData = useCallback(async (filters: { startDate: string; endDate: string }) => {
    setIsLoading(true);
    try {
      const chartFilters: ChartFilters = {
        farmId,
        startDate: filters.startDate,
        endDate: filters.endDate,
      };

      if (wiseconnZoneId) {
        chartFilters.wiseconnZoneId = wiseconnZoneId;
      }

      const preparedData: any = await prepareData(chartFilters);

      // Calcular datos sumados según las profundidades seleccionadas
      const summedSeries = calculateSummedSeries(preparedData, internalSelectedDepths);

      // Calculate y-axis range
      const range = calculateYAxisRange(summedSeries);
      setYAxisRange(range);

      // Transform data into x,y point format
      setChartData({
        series: internalSelectedDepths.length > 0
          ? [{
            type: 'line',
            name: 'Suma de Profundidades',
            data: summedSeries,
          }]
          : [{
            type: 'line',
            name: 'Sin datos seleccionados',
            data: [],
          }],
        categories: preparedData.categories,
        absoluteCategories: preparedData.absoluteCategories,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [farmId, wiseconnZoneId, internalSelectedDepths, calculateSummedSeries, calculateYAxisRange]);

  useEffect(() => {
    fetchData({ startDate, endDate });
  }, [startDate, endDate, fetchData]);

  const customTooltip = useMemo(() => ({
    shared: true,
    useHTML: true,
    formatter(
      this: Highcharts.TooltipFormatterContextObject,
    ) {
      if (this.series.name === 'Comentarios') {
        const point = this.point as ScatterPoint;
        const conversationId = point.custom?.conversationId;
        const conversation = conversations?.find((conv) => conv.conversationId === conversationId);
        const lastComment = conversation?.lastComment || 'Sin comentarios';
        return `<b>Último comentario:</b> ${lastComment}<br/>
                <span style="color: gray; font-size: 10px">${dayjs(conversation?.updatedAt).format('MMM DD, YYYY HH:mm')}</span>`;
      }
      const pointIndex = this.points?.[0].point.index;
      const category = dayjs(chartData?.absoluteCategories?.[pointIndex as number]).format('MMM DD, YYYY HH:mm');
      const header = '<div style="font-size: 14px; font-weight: bold; margin-bottom: 8px;">Resumen</div>';
      const subHeader = `<div style="font-size: 12px; margin-bottom: 8px;">${category}</div>`;
      const points = this.points?.map(
        (point) => `<div style="margin: 4px 0"><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${Number(point.y).toFixed(2)}</b></div>`,
      ).join('');
      return `<div style="padding: 8px">${header}${subHeader}${points}</div>`;
    },
  }), [chartData.absoluteCategories, conversations]);

  useEffect(() => {
    if (!externalConversations) {
      const loadConversations = async () => {
        try {
          const { data } = await apiClient.conversation.conversationDetail(
            sectorId,
            SOIL_MOISTURE_NAME,
          );
          setInternalConversations(data.conversations);
        } catch (err) {
          const { error } = err as FetchError;
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      };

      loadConversations();
    }
  }, [sectorId, externalConversations]);

  const handleConfigClick = useCallback(() => {
    // history.push(`/layout/sectores/${sectorId}`);
    // const params = new URLSearchParams({
    //   tab: 'graph',
    //   sensorType: 'soil'
    // });
    // history.push(`/layout/sectores/${sectorId}?${params.toString()}`);
    navigateToGraph('humedad-suelo', history, {
      farmId,
      sectorId,
      wiseconnZoneId: wiseconnZoneId || '',
      breadcrumb: breadcrumb || '',
    });
  }, [history, farmId, sectorId, wiseconnZoneId]);

  const chartConfig = useMemo(() => ({
    chart: {
      height,
    },
    xAxis: {
      categories: hideXValues ? [] : chartData.categories,
      labels: {
        step: 1,
        enabled: !hideXValues,
      },
    },
    yAxis: {
      min: yAxisRange.min,
      max: yAxisRange.max,
      plotBands,
      plotLines,
      labels: {
        enabled: !hideYValues,
      },
      title: {
        text: yAxisTitle,
      },
    },
  }), [
    height,
    hideXValues,
    chartData.categories,
    yAxisRange.min,
    yAxisRange.max,
    hideYValues,
    yAxisTitle,
    plotBands,
    plotLines,
  ]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={height}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <div>
        {isDashboardChart && (
          <p style={{ marginLeft: '80px', fontSize: '12px', color: '#666' }}>
            Profundidades seleccionadas: {internalSelectedDepths.length > 0 ? internalSelectedDepths.join(', ') : 'Ninguna'}
          </p>
        )}
        <BaseHighchart
          isDashboardChart={isDashboardChart}
          title={title}
          yAxisTitle={yAxisTitle}
          series={chartData.series as Highcharts.SeriesOptionsType[]}
          tooltip={customTooltip}
          chartConfig={chartConfig}
          hideFilter={hideFilter}
          filter={<ChartFilter sectorId={sectorId} />}
          onConfigClick={handleConfigClick}
          showIcons={showIcons}
          hideYValues={hideYValues}
          legend={{ enabled: !hideLegend }}
          setModalState={setModalState}
          conversations={conversations}
          absoluteCategories={chartData.absoluteCategories}
          height={height}
          showFullScreen={true}
        />
      </div>
      <ConversationModal
        modalState={modalState}
        setModalState={setModalState}
        farmId={farmId}
        sectorId={sectorId}
        chart={SOIL_MOISTURE_NAME}
        setConversationsFunction={setConversations}
      />
    </>
  );
}

export default SoilMoistureChart;
