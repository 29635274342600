import React, { useState, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Fullscreen from 'highcharts/modules/full-screen';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CommentIcon from '@mui/icons-material/Comment';
import SettingsIcon from '@mui/icons-material/Settings';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useSelector } from 'react-redux';
import useStyles from './styles';
import { getGranularity, getTimePoint, getScatterConversations } from '../../utils/highcharts';
import { ReduxState } from '../../types';
import { ConversationSchema, TimePointSchema } from '../../../server/schemas/apiTypes';
import { ScatterPoint } from './types';
import commentIcon from '../../assets/svg/graphComment.svg';
// Initialize the modules
Fullscreen(Highcharts);

function BaseHighchart({
  chartType = 'line',
  title = '',
  series = [],
  categories = [],
  yAxisTitle = '',
  xAxisTitle = '',
  colors = ['#536e7b', '#35a0d7', '#77d863', '#f7a35c', '#8085e9', '#f15c80', '#FEBF23', '#2b908f', '#f45b5b', '#91e8e1'],
  legend = { enabled: true },
  tooltip = {},
  chartConfig = {}, // Configuraciones personalizadas adicionales
  hideFilter = false,
  tooltipText = '',
  showFullScreen = true,
  showMarkers = false,
  xPlotBands = [],
  yPlotBands = [],
  hideYValues = false,
  hideXValues = false,
  button = null,
  isDashboardChart = false,
  filter,
  onConfigClick,
  showIcons = true,
  setModalState,
  conversations,
  absoluteCategories,
  height = 300,
  ...props
}: {
  chartType?: string,
  title?: string,
  series?: Array<Highcharts.SeriesOptionsType>,
  categories?: Array<string>,
  yAxisTitle?: string,
  xAxisTitle?: string,
  colors?: Array<string>,
  legend?: Highcharts.LegendOptions,
  tooltip?: Highcharts.TooltipOptions,
  chartConfig?: any,
  hideFilter?: boolean,
  tooltipText?: string,
  showFullScreen?: boolean,
  showMarkers?: boolean,
  xPlotBands?: Array<object>,
  yPlotBands?: Array<object>,
  hideYValues?: boolean,
  hideXValues?: boolean,
  button?: React.ReactNode,
  isDashboardChart?: boolean,
  filter?: React.ReactNode,
  onConfigClick?: () => void,
  showIcons?: boolean,
  setModalState?: React.Dispatch<React.SetStateAction<{
    open: boolean,
    conversationId: string,
    timePointData: TimePointSchema,
  }>>,
  conversations?: Array<ConversationSchema>,
  absoluteCategories?: string[],
  height?: number,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const [isCommentModeActive, setIsCommentModeActive] = useState(false);
  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);

  const handleCommentButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsCommentModeActive(!isCommentModeActive);
  };

  const handleFullScreenClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (chartRef.current?.chart) {
      chartRef.current.chart.fullscreen.toggle();
    }
  };

  const granularity = getGranularity(startDate, endDate);

  const renderCommentMarkers = () => {
    if (!conversations || !absoluteCategories?.length) return [];
    const yAxis = chartRef.current?.chart.yAxis[0];

    return [{
      type: 'scatter',
      name: 'Comentarios',
      data: getScatterConversations(
        conversations,
        granularity,
        absoluteCategories,
        { min: yAxis?.min ?? 0, max: yAxis?.max ?? 1 },
        title,
      ) as ScatterPoint[],
      showInLegend: false,
      marker: {
        symbol: `url(${commentIcon})`,
        width: 42,
        height: 42,
      },
      events: {
        click(e: Highcharts.PointClickEventObject) {
          const point = e.point as ScatterPoint;
          if (setModalState && point.custom) {
            setModalState({
              open: true,
              conversationId: point.custom.conversationId,
              timePointData: point.custom.timePointData,
            });
          }
        },
      },
    }];
  };

  const defaultFontStyles = {
    fontSize: '11px',
    fontWeight: 'normal',
    color: '#385576',
    fontFamily: "'Roboto', sans-serif",
    letterSpacing: '0.25px',
  };

  const chartOptions = {
    chart: {
      type: chartType,
      alignTicks: true,
      marginLeft: 60,
      height,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories,
      title: {
        text: xAxisTitle,
        style: {
          ...defaultFontStyles,
        },
      },
      alignTicks: true,
      tickmarkPlacement: 'on',
      gridLineWidth: 1,
      gridLineColor: '#E0E0E0',
      gridLineDashStyle: 'Dot',
      labels: {
        enabled: !hideXValues,
        style: {
          color: '#385576',
        },
      },
      plotBands: xPlotBands?.map((band: any) => ({
        from: band.from,
        to: band.to,
        color: band.color,
        label: {
          text: band.name || '',
          verticalAlign: 'bottom',
          align: 'right',
          y: -2,
          x: -10,
          useHTML: true,
          style: {
            color: '#000',
            fontSize: '11px',
          },
        },
      })),
    },
    yAxis: {
      title: {
        text: yAxisTitle,
        style: {
          ...defaultFontStyles,
          fontSize: '11px',
        },
        margin: 14,
      },
      labels: {
        enabled: !hideYValues,
        style: {
          color: '#385576',
        },
      },
      plotBands: yPlotBands?.map((band: any) => ({
        from: band.from,
        to: band.to,
        color: band.color,
        label: {
          text: band.name || '',
          verticalAlign: 'bottom',
          align: 'right',
          y: -2,
          x: -10,
          useHTML: true,
          style: {
            color: '#000',
            fontSize: '11px',
          },
        },
      })),
    },
    legend: {
      ...legend,
      itemStyle: {
        ...defaultFontStyles,
      },
      verticalAlign: 'top',
      layout: 'horizontal',
      align: 'left',
      itemDistance: 20,
      itemMarginBottom: isDashboardChart ? 0 : 36,
      x: 10,
    },
    tooltip: {
      ...tooltip,
      outside: true,
      style: {
        ...defaultFontStyles,
      },
      positioner(this: Highcharts.TooltipPositionerPointObject,
        labelWidth: number,
        labelHeight: number,
        point: Highcharts.Point) {
        const { chart } = this as unknown as { chart: Highcharts.Chart };
        let x = (point.plotX ?? 0) + chart.plotLeft;
        let y = (point.plotY ?? 0) + chart.plotTop;

        // Ensure tooltip doesn't go off the right side
        if (x + labelWidth > chart.chartWidth) {
          x = chart.chartWidth - labelWidth;
        }

        // Ensure tooltip doesn't go off the left side
        if (x < 0) {
          x = 0;
        }

        // Position above the point if there's room, otherwise below
        if (y - labelHeight > 0) {
          y -= labelHeight;
        } else {
          y += 20; // Add some padding when showing below
        }

        return { x, y };
      },
    },
    colors,
    series: [...series, ...renderCommentMarkers()],
    credits: {
      enabled: false,
    },
    plotOptions: {
      scatter: {
        marker: {
          enabled: true,
          zIndex: 3,
          states: {
            hover: {
              enabled: true,
              radius: 100, // Increase the hit area
            },
          },
        },
      },
      bar: {
        centerInCategory: true,
      },
      line: {
        lineWidth: 1,
        marker: {
          enabled: showMarkers,
        },
      },
      column: {
        stacking: 'normal',
      },
    },
    lang: {
      noData: 'No hay datos para mostrar',
    },
    noData: {
      style: {
        fontSize: '18px',
        fontWeight: '400px',
      },
    },
  };

  const options = {
    ...chartOptions,
    ...chartConfig,
    ...props,
    plotOptions: {
      ...chartOptions.plotOptions,
      series: {
        point: {
          events: {
            click(this: Highcharts.Point, e: Highcharts.PointerEventObject) {
              if (isCommentModeActive && absoluteCategories) {
                const pointIndex = this.index;
                const timePoint = getTimePoint(pointIndex, absoluteCategories);

                if (setModalState && timePoint) {
                  setModalState({ open: true, conversationId: '', timePointData: timePoint });
                }

                setIsCommentModeActive(false);
                e.stopPropagation();
              }
            },
          },
        },
      },
    },
    chart: {
      ...chartOptions.chart,
      events: {
        click(this: Highcharts.Chart, e: Highcharts.PointerEventObject) {
          if (isCommentModeActive && absoluteCategories) {
            const xAxis = this.xAxis[0];
            const pointIndex = Math.round(xAxis.toValue(e.chartX));

            const timePoint = getTimePoint(pointIndex, absoluteCategories);

            if (setModalState && timePoint) {
              setModalState({ open: true, conversationId: '', timePointData: timePoint });
            }

            setIsCommentModeActive(false);
          }
        },
      },
    },
  };

  return isDashboardChart ? (
    <div className={classes.dashboardChart}>
      <Box className={classes.dashboardChartTitleContainer}>
        <Typography className={classes.dashboardChartTitle} variant="h4" color="primary">
          {title}
        </Typography>
        {/* {button && <Box>{button}</Box>} */}
      </Box>
      <Box className={classes.dashboardChartContainer} sx={{ cursor: isCommentModeActive ? 'crosshair' : 'default' }}>
        <Box className={classes.iconButtonsContainer}
          sx={{
            top: title === 'Sumatoria humedad de suelo' ? '-20px !important' : '10px',
          }}
        >
          <IconButton
            size="small"
            onClick={handleCommentButtonClick}
            sx={{ padding: '4px' }}
          >
            <CommentIcon sx={{
              color: isCommentModeActive ? '#3eab59' : 'primary.main',
              fontSize: '18px',
            }} />
          </IconButton>
          {onConfigClick && (
            <IconButton
              size="small"
              onClick={onConfigClick}
              sx={{ padding: '4px' }}
            >
              <SettingsIcon sx={{ color: '#385576', fontSize: '18px' }} />
            </IconButton>
          )}
          {showFullScreen && (
            <IconButton
              size="small"
              onClick={handleFullScreenClick}
              sx={{ padding: '4px' }}
            >
              <FullscreenIcon sx={{ color: '#385576', fontSize: '18px' }} />
            </IconButton>
          )}
        </Box>
        {!hideFilter && filter}
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          options={options}
        />
      </Box>
    </div>
  ) : (
    <Card className={classes.chartCard}>
      <CardContent>
        {showIcons ? (
          <Accordion
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={expanded ? (
                <RemoveIcon sx={{ color: '#385576' }} />
              ) : (
                <AddIcon sx={{ color: '#385576' }} />
              )}
              sx={{
                '& .MuiAccordionSummary-content': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: 0,
                },
              }}
            >
              <Box className={classes.titleContainer}>
                <Typography variant="h4" color="primary">
                  {title}
                </Typography>
                {tooltipText && (
                  <Tooltip title={<div className={classes.tooltipText}>{tooltipText}</div>} arrow>
                    <div style={{ cursor: 'help' }}>
                      <span className={classes.tooltip}>!</span>
                    </div>
                  </Tooltip>
                )}
              </Box>
              <Box sx={{ display: 'flex', gap: 1, marginRight: 2 }}>
                <IconButton
                  size="small"
                  onClick={handleCommentButtonClick}
                  sx={{ padding: '4px' }}
                >
                  <CommentIcon sx={{
                    color: isCommentModeActive ? '#3eab59' : 'primary.main',
                    fontSize: '18px',
                  }} />
                </IconButton>
                {onConfigClick && (
                  <IconButton
                    size="small"
                    onClick={onConfigClick}
                    sx={{ padding: '4px' }}
                  >
                    <SettingsIcon sx={{ color: '#385576', fontSize: '18px' }} />
                  </IconButton>
                )}
                {showFullScreen && (
                  <IconButton
                    size="small"
                    onClick={handleFullScreenClick}
                    sx={{ padding: '4px' }}
                  >
                    <FullscreenIcon sx={{ color: '#385576', fontSize: '18px' }} />
                  </IconButton>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {button && <Box>{button}</Box>}
              {!hideFilter && filter}
              <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                options={options}
              />
            </AccordionDetails>
          </Accordion>
        ) : (
          <Box>
            <Box className={classes.titleContainer}>
              <Typography variant="h4" color="primary">
                {title}
              </Typography>
              {tooltipText && (
                <Tooltip title={<div className={classes.tooltipText}>{tooltipText}</div>} arrow>
                  <div style={{ cursor: 'help' }}>
                    <span className={classes.tooltip}>!</span>
                  </div>
                </Tooltip>
              )}
            </Box>
            <Box sx={{ mt: 2 }}>
              {!hideFilter && filter}
              {button && <Box>{button}</Box>}
              <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                options={options}
              />
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

export default BaseHighchart;
