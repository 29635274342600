import React from 'react';
import StackedSoilMoistureChart from '../../../../../../components/Highcharts/StackedSoilMoistureChart/StackedSoilMoistureChart';
import SoilMoistureChart from '../../../../../../components/Highcharts/SoilMoistureChart/SoilMoistureChart';
import RootsChart from '../../../../../../components/Highcharts/RootsChart/RootsChart';

const SoilGraphsTab = ({
  sectorId, farmId, wiseconnZoneId, breadcrumb,
}: {
  sectorId: string,
  farmId: string,
  wiseconnZoneId: string | undefined,
  breadcrumb: string,
}) => (
    <>
      <StackedSoilMoistureChart
        hideFilter={true}
        sectorId={sectorId}
        farmId={farmId}
        wiseconnZoneId={wiseconnZoneId}
        height={450}
      />
      <RootsChart
        breadcrumb={breadcrumb}
        hideFilter={true}
        sectorId={sectorId}
        farmId={farmId}
        wiseconnZoneId={wiseconnZoneId}
        height={300}
      />
      <SoilMoistureChart
        hideFilter={true}
        sectorId={sectorId}
        farmId={farmId}
        wiseconnZoneId={wiseconnZoneId}
        height={450}
        breadcrumb={breadcrumb}
      />
    </>
);

export default SoilGraphsTab;
