import { FarmSchema } from '../requests/api/apiTypes';

export const SET_ACCOUNT_FARM = '@account-farm/set-account-farm';
export const SET_ACCOUNT_FARMS_OPTIONS = '@account-farm/set-account-farms-options';
export const RESET_ACCOUNT_FARM = '@account-farm/reset-account-farm';

export const setAccountFarm = (farmData: FarmSchema) => {
  // Store farm data in localStorage
  localStorage.setItem('selectedFarmId', farmData.id as string);

  return {
    type: SET_ACCOUNT_FARM,
    payload: {
      ...farmData,
    },
  };
};

export const setAccountFarmsOptions = (allFarmsData: FarmSchema[]) => ({
  type: SET_ACCOUNT_FARMS_OPTIONS,
  payload: [...allFarmsData],
});

export const resetAccountFarm = () => ({
  type: RESET_ACCOUNT_FARM,
});
