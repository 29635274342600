import dayjs from 'dayjs';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import BaseHighchart from '../BaseHighchart';
import prepareData from './builder';
import useStyles from '../styles';

import {
  ChartDataType,
  ChartFilters,
} from '../types';

import { ReduxState } from '../../../types';
import ChartFilter from '../ChartFilter';
import { navigateToGraph } from '../../../utils/helpers';

function StackedSoilMoistureChart({
  sectorId,
  farmId,
  wiseconnZoneId,
  hideFilter = false,
  hideLegend = false,
  hideXValues = false,
  hideYValues = false,
  showIcons = true,
  height = 600,
  title = 'Gráfico de humedad de suelo apilado',
  yAxisTitle = 'Humedad Suelo (%)',
  isDashboardChart = false,
}: {
  sectorId: string
  farmId: string
  wiseconnZoneId: string | undefined
  hideFilter?: boolean
  hideLegend?: boolean
  hideXValues?: boolean
  hideYValues?: boolean
  showIcons?: boolean
  height?: number
  title?: string
  yAxisTitle?: string
  isDashboardChart?: boolean
}) {
  const classes = useStyles();
  const [chartData, setChartData] = useState<ChartDataType>({
    series: [],
    categories: [],
  });
  const [subtractDepth, setSubtractDepth] = useState(true);
  const [rawData, setRawData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const { startDate, endDate } = useSelector((state: ReduxState) => state.graphsFilter);

  const transformData = (preparedData: any, shouldSubtractDepth: boolean) => {
    const FIXED_OFFSET = 5; // Fixed offset value for better visualization
    const seriesData = preparedData.series.map((item: any, index: number) => ({
      type: 'line',
      name: item.name,
      data: item.data.map((value: number, idx: number) => ({
        x: idx,
        y: shouldSubtractDepth ? value - (index * FIXED_OFFSET) : value,
        custom: {
          date: preparedData.absoluteCategories[idx],
        },
      })),
    }));

    setChartData({
      series: [...seriesData],
      categories: preparedData.categories as string[],
      absoluteCategories: preparedData.absoluteCategories,
    });
  };

  const fetchData = async (filters: { startDate: string; endDate: string }) => {
    setIsLoading(true);
    try {
      const chartFilters: ChartFilters = {
        farmId,
        startDate: filters.startDate,
        endDate: filters.endDate,
      };

      if (wiseconnZoneId) {
        chartFilters.wiseconnZoneId = wiseconnZoneId;
      }
      const preparedData = await prepareData(chartFilters);
      setRawData(preparedData);
      transformData(preparedData, subtractDepth);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData({ startDate, endDate });
  }, [startDate, endDate]);

  useEffect(() => {
    if (rawData) {
      transformData(rawData, subtractDepth);
    }
  }, [subtractDepth]);

  const customTooltip = useMemo(() => ({
    shared: true,
    useHTML: true,
    formatter(
      this: Highcharts.TooltipFormatterContextObject,
    ) {
      const header = '<div style="font-size: 14px; font-weight: bold; margin-bottom: 8px;">Resumen</div>';
      const subHeader = `<div style="font-size: 12px; margin-bottom: 8px;">
        ${dayjs(this.point?.custom?.date).format('MMM DD, YYYY HH:mm')}
      </div>`;
      const points = this.points?.map(
        (point, index) => {
          const displayValue = subtractDepth ? Number(point.y) + (index * 5) : Number(point.y);
          return `<div><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${displayValue.toFixed(2)}</b></div>`;
        },
      ).join('');
      return `
        <div style="
          padding: 10px;
          background: rgba(255, 255, 255, 1);
          border-radius: 5px;">
          ${header}${subHeader}${points}
        </div>
      `;
    },
  }), [subtractDepth]);

  const chartConfig = useMemo(() => ({
    chart: {
      height,
    },
    xAxis: {
      categories: hideXValues ? [] : chartData.categories,
      labels: {
        step: 1,
        enabled: !hideXValues,
      },
    },
  }), [height, hideXValues, chartData.categories]);

  const handleConfigClick = () => {
    navigateToGraph('humedad-suelo-apilada', history, {
      farmId,
      sectorId,
      wiseconnZoneId: wiseconnZoneId || '',
    });
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={height}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <BaseHighchart
        isDashboardChart={isDashboardChart}
        title={title}
        yAxisTitle={subtractDepth ? '' : yAxisTitle}
        series={chartData.series as Highcharts.SeriesOptionsType[]}
        tooltip={customTooltip}
        hideFilter={hideFilter}
        hideYValues={subtractDepth || hideYValues}
        showFullScreen={true}
        legend={{ enabled: !hideLegend }}
        button={
          <button
            onClick={() => setSubtractDepth(!subtractDepth)}
            className={classes.button}
          >
            {subtractDepth ? 'Valores Originales' : 'Apilar'}
          </button>
        }
        filter={<ChartFilter sectorId={sectorId} />}
        chartConfig={chartConfig}
        onConfigClick={handleConfigClick}
        showIcons={showIcons}
        absoluteCategories={chartData.absoluteCategories}
        height={height}
      />
    </div>
  );
}

export default StackedSoilMoistureChart;
