import { useLocation } from 'react-router-dom';
import React from 'react';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import CustomHeader from '../../components/General/CustomHeader';
import Page from '../../components/Layout/Page';
import useStyles from './styles';
import KcChart from '../../components/Highcharts/KcChart/KcChart';

const Kc = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const params = {
    farmId: queryParams.get('farmId') || '',
    sectorId: queryParams.get('sectorId') || '',
    wiseconnZoneId: queryParams.get('wiseconnZoneId') || '',
  };

  return (
    <Page
      className={classes.root}
      title='Coeficiente de cultivo'
    >
      <Container maxWidth={false}>
        <CustomHeader title='TO DO TITULO' />
        <Box mt={3}>
          <KcChart
            hideFilter={false}
            sectorId={params.sectorId}
            farmId={params.farmId}
            wiseconnZoneId={params.wiseconnZoneId}
            showIcons={false}
          />
        </Box>
      </Container>
    </Page>
  );
};
export default Kc;
