import React, { useState } from 'react';
import {
  Box, CircularProgress, MenuItem, TextField,
} from '@mui/material';
import useStyles from '../../styles';
import ChartFilter from '../../../../../../components/Highcharts/ChartFilter';
import useFetchApi from '../../../../../../hooks/useFetchApi';
import { SectorByIdSchemaResponse } from '../../../../../../requests/api/apiTypes';
import SoilGraphsTab from './SoilGraphsTab';
import WeatherGraphsTab from './WeatherGraphsTab';
import IrrigationGraphsTab from './IrrigationGraphsTab';

const SectorGraphs = ({
  sectorId,
  wiseconnZoneId,
  breadcrumb,
}: { sectorId: string, wiseconnZoneId: string | undefined, breadcrumb: string }) => {
  const classes = useStyles({});
  const [sensorType, setSensorType] = useState('clima');

  const { data: fetchData, loading } = useFetchApi<SectorByIdSchemaResponse>({
    modelEndpoint: 'sectorDetail',
    modelName: 'sector',
    id: sectorId,
  });

  const farmId = fetchData?.sector?.farm || '';

  return (
    <>
      <Box className={classes.textFieldsContainer}>
        <TextField
          label='Tipo de sensor'
          variant='standard'
          select
          sx={{ minWidth: 200 }}
          value={sensorType}
          onChange={(e) => setSensorType(e.target.value)}
        >
          <MenuItem value={'suelo'}>Suelo</MenuItem>
          <MenuItem value={'riego'}>Riego</MenuItem>
          <MenuItem value={'clima'}>Clima</MenuItem>
          <MenuItem value={'planta'}>Planta</MenuItem>
        </TextField>
      </Box>
      <ChartFilter sectorId={sectorId} />
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : ((() => {
        switch (sensorType) {
          case 'suelo':
            return <SoilGraphsTab
              sectorId={sectorId}
              farmId={farmId}
              wiseconnZoneId={wiseconnZoneId}
              breadcrumb={breadcrumb}
            />;
          case 'riego':
            return <IrrigationGraphsTab
              sectorId={sectorId}
              farmId={farmId}
              wiseconnZoneId={wiseconnZoneId}
            />;
          case 'clima':
            return <WeatherGraphsTab
              sectorId={sectorId}
              farmId={farmId}
              wiseconnZoneId={wiseconnZoneId}
            />;
          case 'planta':
            return <h1>Componente planta</h1>;
          default:
            return null;
        }
      })()
      )}
    </>
  );
};
export default SectorGraphs;
