import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  highchartContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  chartCard: {
    marginTop: theme.spacing(2),
  },
  dashboardContainer: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  dashboardChart: {
    display: 'flex',
    alignItems: 'stretch', // Asegura que el título y el gráfico tengan alturas iguales
    backgroundColor: theme.palette.background.paper,
  },
  dashboardChartTitleContainer: {
    flex: '0 0 40px', // Ancho fijo de 50px para el título
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dashboardChartTitle: {
    writingMode: 'vertical-rl', // Texto en orientación vertical
    transform: 'rotate(180deg)', // Ajusta el texto para que sea legible
    textAlign: 'center',
    fontSize: '14px',
    fontFamily: 'Roboto',
    color: theme.palette.primary.main,
  },
  dashboardChartContainer: {
    flex: '1', // Ocupa el resto del espacio disponible
    padding: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  iconButtonsContainer: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    display: 'flex',
    gap: '6px',
    zIndex: 1,
  },
  accordionSummary: {
    padding: 0,
  },
  accordionDetails: {
    padding: 0,
  },
  chartFilterContainer: {
    display: 'flex',
    gap: '10px',
    marginBottom: '20px',
    marginLeft: 30,
    borderTop: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
    padding: '20px 0',
  },
  selectFieldsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    width: '100%',
  },
  divider: {
    margin: '5px 0px',
    width: '100%',
  },
  layoutMapChip: {
    margin: '2px',
  },
  selectField: {
    flex: 1,
  },
  chipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '8px',
    padding: '4px 0',
  },
  chipsLabel: {
    color: theme.palette.text.secondary,
    fontSize: '0.7rem',
  },
  tooltip: {
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold',
    border: '1px solid #666',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.text.secondary,
  },

  tooltipText: {
    backgroundColor: '#666',
    padding: '8px 12px',
    borderRadius: '8px',
    color: 'white',
    fontSize: '14px',
  },

  button: {
    marginBottom: '5px',
    marginRight: '15px',
    marginLeft: '15px',
    padding: '8px 16px',
    backgroundColor: '#3693d5',
    color: 'white',
    border: 'none',
    borderRadius: '2px',
    cursor: 'pointer',
  },
}));

export default useStyles;
